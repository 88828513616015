import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { User } from 'src/app/shared/model/user.model';
import { MerchantDetails } from 'src/app/shared/model/merchant-details.model';
import { Role } from 'src/app/shared/model/user-role.enum';
import { Integrator } from 'src/app/shared/model/integrator.model';

@Injectable({
  providedIn: 'any',
})
export class AuthService {
  public static ACCESS_TOKEN = 'access_token';
  public static REFRESH_TOKEN = 'refresh_token';
  public static USER_OBJECT = 'user';
  public static MERCHANT_OBJECT = 'merchant';
  public static INTEGRATOR_OBJECT = 'integrator';
  public static APP_ROUTE = 'route';
  public static LINKED_DEVICE_OBJECT = 'linked_device';
  public static SHOW_PROCEED_TO_SDK = 'show_proceed_to_sdk';

  public user: Observable<User>;
  public merchant: Observable<MerchantDetails>;
  public integrator: Observable<Integrator>;

  private userSubject: BehaviorSubject<User>;
  private merchantSubject: BehaviorSubject<MerchantDetails>;
  private integratorSubject: BehaviorSubject<Integrator>;

  public showLoader = false;

  constructor(
    private logger: NGXLogger,
    private api: ApiService,
    private cookieService: CookieService,
  ) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem(AuthService.USER_OBJECT)));
    this.merchantSubject = new BehaviorSubject<MerchantDetails>(
      JSON.parse(sessionStorage.getItem(AuthService.MERCHANT_OBJECT)),
    );
    this.integratorSubject = new BehaviorSubject<Integrator>(
      JSON.parse(sessionStorage.getItem(AuthService.INTEGRATOR_OBJECT)),
    );

    this.user = this.userSubject.asObservable();
    this.merchant = this.merchantSubject.asObservable();
    this.integrator = this.integratorSubject.asObservable();
    const isLocalhost = globalThis.location?.hostname === 'localhost';
    globalThis.addEventListener('beforeunload', () => !isLocalhost && this.signOut());
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  public get merchantValue(): MerchantDetails {
    return this.merchantSubject.value;
  }

  public get integratorValue(): Integrator {
    return this.integratorSubject.value;
  }

  public get accessToken(): string {
    return sessionStorage.getItem(AuthService.ACCESS_TOKEN);
  }

  public get refreshToken(): string {
    return sessionStorage.getItem(AuthService.REFRESH_TOKEN);
  }

  get isLoggedIn(): boolean {
    return !!this.accessToken;
  }

  get isFicaApproved(): boolean {
    if (this.merchantValue === null) {
      return false;
    }

    if (this.merchantValue.isFicaApproved && this.merchantValue.externalMerchantSystem) {
      const ficaDateString = this.merchantValue.externalMerchantSystem?.ficaDate;

      if (ficaDateString) {
        const ficaDate = new Date(ficaDateString);
        const now = new Date();

        // To calculate the time difference of two dates
        var diffInTime = now.getTime() - ficaDate.getTime();

        // To calculate the no. of days between two dates
        var diffInDays = Math.round(diffInTime / (1000 * 3600 * 24));

        if (diffInDays >= 2) {
          return true;
        }
      }
    } else if (this.merchantValue.isFicaApproved) {
      return true;
    }

    return false;
  }

  async updateMerchantDetails(): Promise<void> {
    const merchant = await this.api.getCurrentMerchant();
    sessionStorage.setItem(AuthService.MERCHANT_OBJECT, JSON.stringify(merchant));
    this.merchantSubject.next(merchant);
  }

  async updateIntegratorDetails(): Promise<void> {
    const integrator = await this.api.getCurrentIntegrator();
    sessionStorage.setItem(AuthService.INTEGRATOR_OBJECT, JSON.stringify(integrator));
    this.integratorSubject.next(integrator);
  }

  async login(username: string, password: string): Promise<any> {
    this.showLoader = true;

    try {
      const loginToken = await this.api.login(username, password);
      sessionStorage.setItem(AuthService.ACCESS_TOKEN, loginToken.token);

      const refreshToken = await this.api.getRefreshToken();
      const user = await this.api.getCurrentUser();
      const userRoles = await this.api.getUserRoles(username);
      const userRole = userRoles[0].name;
      const userRoleName = userRole.charAt(0).toUpperCase() + userRole.slice(1);

      user.role = userRoleName;

      this.userSubject.next(user);

      // store user details and jwt token in local storage to keep user logged in between page refreshes
      sessionStorage.setItem(AuthService.USER_OBJECT, JSON.stringify(user));
      sessionStorage.setItem(AuthService.REFRESH_TOKEN, refreshToken.token);

      if (userRoleName == Role.Merchant || userRoleName == Role.Support) {
        await this.updateMerchantDetails();
      } else if (userRoleName == Role.Integrator) {
        await this.updateIntegratorDetails();
      }

      this.showLoader = false;
    } catch (e) {
      this.showLoader = false;
      throw e;
    }
  }

  async loginWithToken(token: string): Promise<boolean> {
    sessionStorage.setItem(AuthService.ACCESS_TOKEN, token);

    try {
      const refreshToken = await this.api.getRefreshToken();
      const user = await this.api.getCurrentUser();
      const userRoles = await this.api.getUserRoles(user.username);
      const userRole = userRoles[0].name;
      const userRoleName = userRole.charAt(0).toUpperCase() + userRole.slice(1);

      user.role = userRoleName;

      this.userSubject.next(user);

      // store user details and jwt token in local storage to keep user logged in between page refreshes
      sessionStorage.setItem(AuthService.USER_OBJECT, JSON.stringify(user));
      sessionStorage.setItem(AuthService.REFRESH_TOKEN, refreshToken.token);

      if (userRoleName == Role.Merchant || userRoleName == Role.Support) {
        await this.updateMerchantDetails();
      } else if (userRoleName == Role.Integrator) {
        await this.updateIntegratorDetails();
      }

      return true;
    } catch (e) {
      return false;
    }
  }

  removeLoginCredentials(reloadPage = false) {
    sessionStorage.removeItem(AuthService.ACCESS_TOKEN);
    sessionStorage.removeItem(AuthService.REFRESH_TOKEN);
    sessionStorage.removeItem(AuthService.USER_OBJECT);
    sessionStorage.removeItem(AuthService.MERCHANT_OBJECT);
    sessionStorage.removeItem(AuthService.INTEGRATOR_OBJECT);

    this.cookieService.delete(AuthService.ACCESS_TOKEN);
    this.cookieService.delete(AuthService.APP_ROUTE);

    this.userSubject.next(null);
    this.merchantSubject.next(null);
    this.integratorSubject.next(null);
    if (reloadPage) {
      this.user = null;
      this.merchant = null;
      this.integrator = null;
    }
  }

  // Sign out
  signOut(reloadPage = true) {
    // Clean up all instances of injected services and components
    this.removeLoginCredentials(reloadPage);

    if (reloadPage) {
      this.reloadPage();
    }
  }

  async tryRefresh(): Promise<boolean> {
    if (this.refreshToken === null) {
      return false;
    }

    try {
      if (!this.refreshToken) {
        return false;
      }

      const accessToken = await this.api.getAccessToken(this.refreshToken);
      sessionStorage.setItem(AuthService.ACCESS_TOKEN, accessToken.token);

      return true;
    } catch (e) {
      this.logger.error(e);

      sessionStorage.removeItem(AuthService.ACCESS_TOKEN);
      sessionStorage.removeItem(AuthService.REFRESH_TOKEN);

      return false;
    }
  }

  reloadPage() {
    window.location.reload();
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private jwtHelper: JwtHelperService,
    public authService: AuthService,
    public router: Router,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    const accessToken = this.authService.accessToken;

    if (!accessToken || accessToken === null) {
      this.router.navigate(['auth/login']);
      return false;
    }

    try {
      if (this.jwtHelper.isTokenExpired(accessToken)) {
        if (!(await this.authService.tryRefresh())) {
          this.router.navigate(['auth/login']);
          this.authService.removeLoginCredentials();
          return false;
        }
      }
    } catch (error) {
      this.authService.removeLoginCredentials();
      this.router.navigate(['auth/login']);
      return false;
    }

    const user = this.authService.userValue;

    if (user) {
      // check if route is restricted by role
      if (next.data.roles && next.data.roles.indexOf(user.role) === -1) {
        // role not authorized so redirect to home page
        this.router.navigate(['/router']);
        return false;
      }

      // authorized so return true
      return true;
    }

    return true;
  }
}

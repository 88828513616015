import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

import { NavService } from 'src/app/shared/services/nav.service';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit {
  public language = false;

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'ZA',
      icon: 'za',
    },
    {
      language: 'Español',
      code: 'es',
      icon: 'es',
    },
    {
      language: 'Français',
      code: 'fr',
      icon: 'fr',
    },
    {
      language: 'Português',
      code: 'pt',
      type: 'BR',
      icon: 'pt',
    },
    {
      language: 'Россия',
      code: 'ru',
      icon: 'ru',
    },
  ];

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'ZA',
    icon: 'za',
  };

  constructor(
    private translate: TranslateService,
    public navServices: NavService,
    private logger: NGXLogger,
  ) {}

  ngOnInit() {
    this.translate.currentLang = this.selectedLanguage.code;
    this.logger.debug(this.translate.currentLang);
  }

  changeLanguage(lang) {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from 'src/app/shared/auth/login/login.component';
import { ForgotPasswordComponent } from 'src/app/shared/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from 'src/app/shared/auth/reset-password/reset-password.component';
import { RegisterComponent } from 'src/app/shared/auth/register/register.component';
import { ContentComponent } from 'src/app/shared/components/layout/content/content.component';
import { AuthGuard } from 'src/app/shared/guard/auth.guard';
import { routes } from 'src/app/shared/routes/routes';

const navRoutes: Routes = [
  {
    path: 'auth/login',
    component: LoginComponent,
  },
  {
    path: 'auth/forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'auth/reset-password/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'auth/register',
    component: RegisterComponent,
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AuthGuard],
    children: routes,
  },
  {
    path: '**',
    redirectTo: '/router',
  },
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(navRoutes, {
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: false,
  ngxLoggerConfig: {
    level: NgxLoggerLevel.DEBUG,
    disableConsoleLogging: false,
  },
  darkMode: true,
  navLanguages: true,
  navSearch: true,
  navSettings: true,
};

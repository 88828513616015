<div class="container-fluid">
  <div class="row">
    <div
      class="col-xl-5 pl-0 pr-0 authBackground"
      [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }"
    ></div>

    <div class="col-xl-7 p-0">
      <div class="login-card">
        <div class="login-main">
          <form action="#">
            <aw-wizard navBarLayout="large-filled-symbols">
              <!-- STEP 1 - OTP -->
              <aw-wizard-step [navigationSymbol]="{ symbol: '1' }">
                <form class="theme-form" [formGroup]="credentialsForm">
                  <ng-template awWizardStepTitle>
                    <p>OTP</p>
                  </ng-template>
                  <div class="setup-content" id="step-1">
                    <h4>Reset your password</h4>
                    <p>Enter your email or mobile number to receive an OTP</p>

                    <!-- BOTH LOGIN METHODS -->
                    <div *ngIf="loginMethod.includes('mobile') && loginMethod.includes('email'); else singleLogin">
                      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                          <a ngbNavLink>Email</a>
                          <ng-template ngbNavContent>
                            <div class="form-group">
                              <input
                                class="form-control"
                                formControlName="email"
                                placeholder="name@email.com"
                                type="email"
                              />
                            </div>
                          </ng-template>
                        </li>

                        <li [ngbNavItem]="2">
                          <a ngbNavLink>Mobile</a>
                          <ng-template ngbNavContent>
                            <div class="form-group">
                              <div class="input-group">
                                <ngx-intl-tel-input
                                  [cssClass]="'custom'"
                                  [enableAutoCountrySelect]="true"
                                  [enablePlaceholder]="true"
                                  [maxLength]="15"
                                  [numberFormat]="PhoneNumberFormat.National"
                                  [phoneValidation]="true"
                                  [preferredCountries]="preferredCountries"
                                  [searchCountryField]="[
                                    SearchCountryField.Iso2,
                                    SearchCountryField.Name
                                  ]"
                                  [searchCountryFlag]="true"
                                  [selectFirstCountry]="false"
                                  [selectedCountryISO]="CountryISO.SouthAfrica"
                                  [separateDialCode]="separateDialCode"
                                  formControlName="mobile"
                                  name="phone"
                                >
                                </ngx-intl-tel-input>
                              </div>
                            </div>
                          </ng-template>
                          <ngb-alert (closed)="loginPinFailed=false" *ngIf="loginPinFailed" type="danger">
                            Incorrect username or password
                          </ngb-alert>
                        </li>
                      </ul>

                      <form #form="ngForm">
                        <div [ngbNavOutlet]="nav" class="mt-2"></div>
                      </form>
                    </div>

                    <!-- SINGLE LOGIN METHOD -->
                    <ng-template #singleLogin>
                      <div *ngIf="loginMethod.includes('email')" class="form-group">
                        <label class="col-form-label">Email Address</label>
                        <input class="form-control" formControlName="email" placeholder="name@email.com" type="email" />
                        <ng-container
                          *ngIf="credentialsForm.get('email').hasError('required') && credentialsForm.get('email').touched"
                          class="invalid"
                        >
                          <div class="invalid">Email is required</div>
                        </ng-container>
                        <ng-container
                          *ngIf="credentialsForm.get('email').hasError('email') && credentialsForm.get('email').touched"
                          class="invalid"
                        >
                          <div class="invalid">Email not valid</div>
                        </ng-container>
                      </div>

                      <div *ngIf="loginMethod.includes('mobile')" class="form-group">
                        <label class="col-form-label">Mobile Number</label>
                        <ngx-intl-tel-input
                          [cssClass]="'custom'"
                          [enableAutoCountrySelect]="true"
                          [enablePlaceholder]="true"
                          [maxLength]="15"
                          [numberFormat]="PhoneNumberFormat.National"
                          [phoneValidation]="true"
                          [preferredCountries]="preferredCountries"
                          [searchCountryField]="[
                            SearchCountryField.Iso2,
                            SearchCountryField.Name
                          ]"
                          [searchCountryFlag]="true"
                          [selectFirstCountry]="false"
                          [selectedCountryISO]="CountryISO.SouthAfrica"
                          [separateDialCode]="separateDialCode"
                          formControlName="mobile"
                          name="phone"
                        >
                        </ngx-intl-tel-input>
                        <ng-container
                          *ngIf="credentialsForm.get('mobile').hasError('required') && credentialsForm.get('mobile').touched"
                          class="invalid"
                        >
                          <div class="invalid">Mobile number is required</div>
                        </ng-container>
                        <ng-container
                          *ngIf="credentialsForm.get('mobile').hasError('pattern') && credentialsForm.get('mobile').touched"
                          class="invalid"
                        >
                          <div class="invalid">Mobile number not valid</div>
                        </ng-container>
                      </div>
                    </ng-template>

                    <div class="form-group mt-4 mb-0">
                      <button
                        (click)="getOtp(true)"
                        [disabled]="!credentialsFormValid"
                        class="btn btn-primary btn-block"
                        type="button"
                      >
                        <span>Send OTP</span>
                      </button>
                    </div>
                  </div>
                </form>
              </aw-wizard-step>

              <!-- STEP 2 - VERIFY -->
              <aw-wizard-step [navigationSymbol]="{ symbol: '2' }">
                <form class="theme-form" [formGroup]="otpForm">
                  <ng-template awWizardStepTitle>
                    <p>Verify</p>
                  </ng-template>
                  <div class="setup-content" id="step-4">
                    <h4>Let's get you verified!</h4>
                    <p>Please enter the code sent to you</p>
                    <div class="form-group" *ngIf="verifyGUID !== null">
                      <label>Your code will expire in: {{ timeLeft }} seconds</label>
                      <div class="pin">
                        <code-input
                          class="code-input"
                          id="passcode"
                          [isCodeHidden]="true"
                          [codeLength]="4"
                          (codeCompleted)="verifyOtp($event)"
                        >
                        </code-input>
                      </div>
                    </div>
                    <div class="mt-4 mb-0">
                      <p class="mb-1">
                        Didn't receive the code?
                        <a (click)="getOtp(false)" class="ml-1" [routerLink]="" style="cursor: pointer">Resend</a>
                      </p>
                      <button class="btn btn-outline-secondary mt-3" type="button" awPreviousStep>Back</button>
                    </div>
                  </div>
                </form>
              </aw-wizard-step>

              <!-- STEP 3 - PIN -->
              <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" *ngIf="loginType === 'pin'; else forgotPassword">
                <form class="theme-form">
                  <ng-template awWizardStepTitle>
                    <p>Reset</p>
                  </ng-template>
                  <div class="setup-content" id="step-4">
                    <h4>Great! Now let's reset your pin</h4>
                    <p>Create a 4-digit pin to keep your account secure.</p>
                    <div class="form-group">
                      <label class="col-form-label">Password</label>
                      <div class="pin">
                        <code-input
                          class="code-input"
                          id="passcode"
                          [isCodeHidden]="true"
                          [codeLength]="4"
                          (codeChanged)="enterPasscode($event)"
                        >
                        </code-input>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Confirm Password</label>
                      <div class="pin">
                        <code-input
                          class="code-input"
                          id="confirmPassword"
                          [isCodeHidden]="true"
                          [codeLength]="4"
                          (codeChanged)="enterConfirmPasscode($event)"
                        >
                        </code-input>
                      </div>
                    </div>
                    <div class="form-group mb-0">
                      <button
                        class="btn btn-primary pull-right mt-3"
                        type="submit"
                        (click)="resetPassword()"
                        [disabled]="!passcodeValue.valid"
                      >
                        Reset
                      </button>
                      <button class="btn btn-outline-secondary pull-right mr-3 mt-3" type="button" awPreviousStep>
                        Back
                      </button>
                    </div>
                  </div>
                </form>
              </aw-wizard-step>

              <!-- STEP 3 - PASSWORD -->
              <ng-template #forgotPassword>
                <aw-wizard-step [navigationSymbol]="{ symbol: '3' }">
                  <form class="theme-form" [formGroup]="passwordValueForm">
                    <ng-template awWizardStepTitle>
                      <p>Register</p>
                    </ng-template>
                    <div class="setup-content" id="step-4">
                      <h4>Great! Now let's keep you secure</h4>
                      <p>Create a strong password to keep your account secure.</p>
                      <div class="form-group">
                        <label class="col-form-label">Password</label>
                        <input
                          class="form-control"
                          formControlName="password"
                          placeholder="Enter password"
                          [type]="revealPassword ? 'text' : 'password'"
                        />
                        <div class="show-hide" (click)="showPassword()" *ngIf="!revealPassword">
                          <span class="show"></span>
                        </div>
                        <div class="show-hide" (click)="showPassword()" *ngIf="revealPassword">
                          <span class="Hide"></span>
                        </div>
                        <ng-container
                          class="invalid"
                          *ngIf="passwordValueForm.get('password').hasError('required') && passwordValueForm.get('password').touched"
                        >
                          <div class="invalid">Password is required</div>
                        </ng-container>
                        <ng-container
                          class="invalid"
                          *ngIf="passwordValueForm.get('password').hasError('minlength') && passwordValueForm.get('password').touched"
                        >
                          <div class="invalid">Password must be 8 characters or more</div>
                        </ng-container>
                        <ng-container
                          class="invalid"
                          *ngIf="passwordValueForm.get('password').hasError('pattern') && passwordValueForm.get('password').touched"
                        >
                          <div class="invalid">Password must contain a number and uppercase letter</div>
                        </ng-container>
                      </div>
                      <div class="form-group">
                        <label class="col-form-label">Confirm Password</label>
                        <input
                          class="form-control"
                          formControlName="confirmPassword"
                          placeholder="Enter password"
                          type="password"
                        />
                        <ng-container
                          class="invalid"
                          *ngIf="passwordValueForm.get('confirmPassword').hasError('required') && passwordValueForm.get('confirmPassword').touched"
                        >
                          <div class="invalid">Password confirmation is required</div>
                        </ng-container>
                        <ng-container
                          class="invalid"
                          *ngIf="passwordValueForm.get('confirmPassword').hasError('minlength') && passwordValueForm.get('confirmPassword').touched"
                        >
                          <div class="invalid">Password confirmation must be 8 characters or more</div>
                        </ng-container>
                        <ng-container
                          class="invalid"
                          *ngIf="passwordValueForm.get('confirmPassword').hasError('pattern') && passwordValueForm.get('confirmPassword').touched"
                        >
                          <div class="invalid">Password must contain a number and uppercase letter</div>
                        </ng-container>
                        <ng-container
                          class="invalid"
                          *ngIf="!passwordsMatch() && passwordValueForm.get('confirmPassword').touched"
                        >
                          <div class="invalid">Passwords do not match</div>
                        </ng-container>
                      </div>
                      <div class="form-group mb-0">
                        <button
                          class="btn btn-primary pull-right mt-3"
                          type="submit"
                          (click)="resetPassword()"
                          [disabled]="passwordValueForm.controls['password'].value !== passwordValueForm.controls['confirmPassword'].value"
                        >
                          Reset
                        </button>
                        <button class="btn btn-outline-secondary pull-right mr-3 mt-3" type="button" awPreviousStep>
                          Back
                        </button>
                      </div>
                    </div>
                  </form>
                </aw-wizard-step>
              </ng-template>
            </aw-wizard>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';
import { NGXLogger } from 'ngx-logger';
import { concatMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';

import { isExternalUrl } from 'src/app/shared/utils/utils';

@Injectable({ providedIn: 'root' })
export class JwtExpiryInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private logger: NGXLogger,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      isExternalUrl(req) &&
      !req.url.endsWith('/login') &&
      !req.url.endsWith('/exchangeKeys') &&
      !req.url.endsWith('/haloDotGoUrl') &&
      !req.url.endsWith('/getMerchantDocuments') &&
      !req.url.endsWith('/register/JWT') &&
      !req.url.endsWith('/generateAccessToken') &&
      !req.url.endsWith('/accessToken')
    ) {
      const hasAccessToken = this.authService.accessToken;
      const hasRefreshToken = this.authService.refreshToken;

      if (hasRefreshToken) {
        const expiry = JSON.parse(atob(hasRefreshToken.split('.')[1])).exp;

        if (Math.floor(new Date().getTime() / 1000) >= expiry) {
          this.logger.debug('Refresh token has expired');
          this.authService.signOut();
        }
      }

      if (hasAccessToken) {
        const expiry = JSON.parse(atob(hasAccessToken.split('.')[1])).exp;

        if (Math.floor(new Date().getTime() / 1000) >= expiry) {
          this.logger.debug('Access token has expired');

          return from(this.getRequestInfo(req)).pipe(
            concatMap((requestInfo) => {
              if (requestInfo.result) {
                return next.handle(
                  requestInfo.req.clone({
                    headers: requestInfo.req.headers.set('Authorization', `Bearer ${this.authService.accessToken}`),
                  }),
                );
              } else {
                this.authService.signOut();

                return next.handle(requestInfo.req);
              }
            }),
            concatMap((event) => Promise.resolve(event)),
          );
        } else {
          req = req.clone({
            setHeaders: { Authorization: `Bearer ${this.authService.accessToken}` },
          });

          return next.handle(req);
        }
      } else {
        return next.handle(req);
      }
    } else {
      return next.handle(req);
    }
  }

  async getRequestInfo(req: HttpRequest<any>): Promise<{
    req: HttpRequest<any>;
    result: boolean;
  }> {
    var result = await this.authService.tryRefresh();
    return {
      req,
      result,
    };
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { NGXLogger } from 'ngx-logger';

import { getCarouselImages, loadWebConfig } from 'src/app/shared/utils/utils';
import { LoginType } from 'src/app/shared/model/auth-channels';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { IWebConfig } from 'src/app/IWebConfig';
import { User } from '../../model/user.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [style({ transform: 'translateX(100%)' }), animate('300ms ease-out')]),
      transition('* => void', [animate('300ms ease-out', style({ transform: 'translateX(-100%)' }))]),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  private webconfig: IWebConfig = loadWebConfig();
  public logo = this.webconfig.assets.logo;
  public logoWhite = this.webconfig.assets.logoWhite;
  public orgId = this.webconfig.orgId;
  public loginType = this.webconfig.loginType.toLocaleLowerCase();
  public loginMethod = this.webconfig.loginMethod;
  public accountType = this.webconfig.accountType;
  public isAllowedToCreateAccount = this.webconfig.isAllowedToCreateAccount;

  public credentialsForm: FormGroup;

  public active = 1;

  public isShortPassword = this.loginType === LoginType.Pin;
  public revealPassword = false;
  public incorrectPassword = false;

  public backgroundImage = getCarouselImages();
  public separateDialCode = false;
  public preferredCountries: CountryISO[] = [CountryISO.SouthAfrica, CountryISO.UnitedKingdom, CountryISO.UnitedStates];

  public SearchCountryField = SearchCountryField;
  public CountryISO = CountryISO;
  public PhoneNumberFormat = PhoneNumberFormat;

  constructor(
    public api: ApiService,
    public authService: AuthService,
    private logger: NGXLogger,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    this.credentialsForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }
  ngOnInit(): void {
    this.authService.removeLoginCredentials();
  }

  public showPassword() {
    this.revealPassword = !this.revealPassword;
  }

  public async login(password?: string) {
    if (!password) {
      password = this.credentialsForm.controls.password.value;
    } else {
      this.credentialsForm.controls.password.setValue(password);
    }

    if (this.credentialsForm.invalid) {
      this.logger.error('Invalid login form');
      return;
    }

    try {
      let username: string;
      let user: User;

      // User active tab to determine whether initially username is email or mobile
      if (this.active === 1) {
        username = this.credentialsForm.controls.username.value.toLowerCase().trim();
        user = await this.api.getUserByEmail(username);
      } else if (this.active === 2) {
        username = this.credentialsForm.controls.username.value.e164Number;
        user = await this.api.getUserByMobile(username);
      } else {
        this.logger.error('Invalid active tab: ', this.active);
        return;
      }

      if (!username) {
        this.logger.error('Invalid username');
        return;
      }

      // If email entered but username is mobile and exists, use mobile to login
      if (user.email && user.username === user.phonenumber) {
        username = user.phonenumber;
      }

      // If mobile entered but username is email and exists, use email to login
      if (user.phonenumber && user.username === user.email) {
        username = user.email;
      }

      await this.authService.login(username, password);
      const authUser = this.authService.userValue;

      if (authUser) {
        this.router.navigate(['/router']);
      }
    } catch (e) {
      this.incorrectPassword = true;
      this.logger.error('Failed to login: ', e.message);
    }
  }

  clearInput() {
    const username = this.credentialsForm.get('username');
    username.setValue('');
    username.markAsTouched();
  }
}

import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { NavService } from 'src/app/shared/services/nav.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { MerchantDetails } from 'src/app/shared/model/merchant-details.model';
import { User } from 'src/app/shared/model/user.model';
import { Role } from 'src/app/shared/model/user-role.enum';
import { AuthService } from 'src/app/shared/services/auth.service';
import { loadWebConfig } from 'src/app/shared/utils/utils';
import { IWebConfig } from 'src/app/IWebConfig';
import { environment } from 'src/environment/environment';
import { PortalId } from 'src/app/shared/model/portal-id.enum';
import { OrgId } from 'src/app/shared/model/org-id.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  private webconfig: IWebConfig = loadWebConfig();
  public logo = this.webconfig.assets.logo;
  public logoWhite = this.webconfig.assets.logoWhite;
  public orgId = this.webconfig.orgId;
  public portalId = this.webconfig.portalId;

  public PortalId = PortalId;
  public OrgId = OrgId;

  public darkMode = environment.darkMode;
  public navSearch = environment.navSearch;
  public navLanguages = environment.navLanguages;

  public readonly roleEnum: typeof Role = Role;

  public dark: boolean = this.layout.config.settings.layout_version == 'dark-only' ? true : false;
  public demoMode = true;
  public userRole: Role = null;
  public elem: any;

  constructor(
    private authService: AuthService,
    public layout: LayoutService,
    public navServices: NavService,
    @Inject(DOCUMENT) private document: any,
  ) {}

  ngOnInit() {
    this.elem = document.documentElement;
    this.authService.merchant.subscribe((merchant: MerchantDetails) => {
      if (merchant !== null) {
        this.demoMode = merchant.demoMode;
      }
    });
    this.authService.user.subscribe((user: User) => {
      if (user !== null) {
        this.userRole = user.role;
      }
    });
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  layoutToggle() {
    this.dark = !this.dark;
    this.layout.config.settings.layout_version = this.dark ? 'dark-only' : 'light-only';
  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}

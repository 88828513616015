import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dateFormatter' })
export class DateFormatterPipe implements PipeTransform {
  datePipe = new DatePipe('en-ZA');

  public transform(input: any): any {
    return this.datePipe.transform(input, 'HH:mm:ss dd/MM/YYYY');
  }
}

@Pipe({ name: 'dateTimeFormatter' })
export class DateTimeFormatterPipe implements PipeTransform {
  datePipe = new DatePipe('en-ZA');

  public transform(input: any): any {
    return this.datePipe.transform(input, 'dd/MM/YYYY');
  }
}

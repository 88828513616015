<div class="container-fluid">
  <div class="row">
    <div
      class="col-xl-5 pl-0 pr-0 authBackground"
      [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }"
    ></div>

    <div class="col-xl-7 p-0">
      <div class="login-card">
        <div class="login-main">
          <form action="#">
            <aw-wizard navBarLayout="large-filled-symbols">
              <!-- STEP 1 - DETAILS -->
              <aw-wizard-step [navigationSymbol]="{ symbol: '1' }">
                <form class="theme-form" [formGroup]="detailsForm">
                  <ng-template awWizardStepTitle>
                    <p>Enter</p>
                  </ng-template>
                  <div class="setup-content" id="step-1">
                    <h4>Let's get you started!</h4>
                    <p>
                      Already have an account?<a class="ml-2" [routerLink]="'/auth/login'" style="cursor: pointer"
                        >Login</a
                      >
                    </p>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label asterisk-if-mandatory">First Name</label>
                          <input
                            class="form-control"
                            type="text"
                            formControlName="firstName"
                            placeholder="Enter your first name"
                            required
                          />
                          <ng-container
                            class="invalid"
                            *ngIf="detailsForm.get('firstName').hasError('required') && detailsForm.get('firstName').touched"
                          >
                            <div class="invalid">First name is required</div>
                          </ng-container>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label asterisk-if-mandatory">Last Name</label>
                          <input
                            class="form-control"
                            type="text"
                            formControlName="lastName"
                            placeholder="Enter your last name"
                          />
                          <ng-container
                            class="invalid"
                            *ngIf="detailsForm.get('lastName').hasError('required') && detailsForm.get('lastName').touched"
                          >
                            <div class="invalid">Last name is required</div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label asterisk-if-mandatory">Business Name</label>
                      <input
                        class="form-control"
                        type="text"
                        formControlName="entityName"
                        placeholder="Enter your business name"
                      />
                      <ng-container
                        class="invalid"
                        *ngIf="detailsForm.get('entityName').hasError('required') && detailsForm.get('entityName').touched"
                      >
                        <div class="invalid">Business name is required</div>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label asterisk-if-mandatory">Country</label>
                      <ng-select
                        [items]="countryList"
                        formControlName="country"
                        bindLabel="Name"
                        bindValue="Name"
                        class="form-control-inverse btn-square ng-select-outline"
                      >
                      </ng-select>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label asterisk-if-mandatory">Email Address</label>
                      <input
                        class="form-control"
                        type="email"
                        formControlName="email"
                        placeholder="Enter your email address"
                      />
                      <ng-container
                        class="invalid"
                        *ngIf="detailsForm.get('email').hasError('required') && detailsForm.get('email').touched"
                      >
                        <div class="invalid">Email address is required</div>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Phone Number</label>
                      <div class="input-group">
                        <ngx-intl-tel-input
                          [cssClass]="'custom'"
                          [preferredCountries]="preferredCountries"
                          [enableAutoCountrySelect]="true"
                          [enablePlaceholder]="true"
                          [searchCountryFlag]="true"
                          [searchCountryField]="[
                              SearchCountryField.Iso2,
                              SearchCountryField.Name
                            ]"
                          [selectFirstCountry]="false"
                          [selectedCountryISO]="CountryISO.SouthAfrica"
                          [maxLength]="15"
                          [phoneValidation]="true"
                          [separateDialCode]="separateDialCode"
                          [numberFormat]="PhoneNumberFormat.National"
                          name="phone"
                          formControlName="mobileNumber"
                        >
                        </ngx-intl-tel-input>
                      </div>
                      <ng-container
                        class="invalid"
                        *ngIf="detailsForm.get('mobileNumber').hasError('required') && detailsForm.get('mobileNumber').touched"
                      >
                        <div class="invalid">Phone number is required</div>
                      </ng-container>
                    </div>
                    <div class="form-group mb-0 mt-4">
                      <div class="checkbox p-0">
                        <input
                          id="usePhoneNumberCheckbox"
                          type="checkbox"
                          formControlName="usePhoneNumberCheckbox"
                          (change)="onUsePhoneNumberSelected($event)"
                        />
                        <label class="text-muted" for="usePhoneNumberCheckbox">
                          Use phone number for receiving OTPs and Login
                        </label>
                      </div>
                      <div class="checkbox p-0">
                        <input id="confirmCheckbox" type="checkbox" formControlName="confirmCheckbox" />
                        <label class="text-muted" for="confirmCheckbox"
                          >We confirm you have read and accepted Halo Dot's<a
                            class="ml-1"
                            (click)="openTermsAndConditions()"
                            >Terms</a
                          ></label
                        >
                      </div>
                      <button
                        class="btn btn-primary btn-block mt-3"
                        type="submit"
                        (click)="validateUniqueness()"
                        [disabled]="!detailsForm.valid"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </form>
              </aw-wizard-step>

              <!-- STEP 2 - OTP -->
              <aw-wizard-step [navigationSymbol]="{ symbol: '2' }">
                <form class="theme-form" [formGroup]="otpForm">
                  <ng-template awWizardStepTitle>
                    <p>Verify</p>
                  </ng-template>
                  <div class="setup-content" id="step-4">
                    <h4>Let's get you verified!</h4>
                    <p>
                      Please enter the code sent to you by <strong>{{ channelType }}</strong>:
                      <strong>{{ channelTypeValue }}</strong>
                    </p>
                    <div class="form-group" *ngIf="verifyGUID !== null">
                      <label>Your code will expire in: {{ timeLeft }} seconds</label>
                      <div class="pin">
                        <code-input
                          class="code-input"
                          id="passcode"
                          [isCodeHidden]="true"
                          [codeLength]="4"
                          (codeCompleted)="verifyOtp($event)"
                        >
                        </code-input>
                      </div>
                    </div>
                    <div class="mt-4 mb-0">
                      <p class="mb-1">
                        Didn't receive the code?
                        <a class="ml-1" [routerLink]="" (click)="getOtp(true)" style="cursor: pointer">Resend</a>
                      </p>
                      <button class="btn btn-outline-secondary mt-3" type="button" awPreviousStep>Back</button>
                    </div>
                  </div>
                </form>
              </aw-wizard-step>

              <!-- STEP 3 - PIN -->
              <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" *ngIf="loginType === 'pin'; else loginPassword">
                <form class="theme-form">
                  <ng-template awWizardStepTitle>
                    <p>Register</p>
                  </ng-template>
                  <div class="setup-content" id="step-4">
                    <h4>Great! Now let's keep you secure</h4>
                    <p>Create a 4-digit pin to keep your account secure.</p>
                    <div class="form-group">
                      <label class="col-form-label">Password</label>
                      <div class="pin">
                        <code-input
                          class="code-input"
                          id="passcode"
                          [isCodeHidden]="true"
                          [codeLength]="4"
                          (codeChanged)="enterPasscode($event)"
                        >
                        </code-input>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Confirm Password</label>
                      <div class="pin">
                        <code-input
                          class="code-input"
                          id="confirmPasscode"
                          [isCodeHidden]="true"
                          [codeLength]="4"
                          (codeChanged)="enterConfirmPasscode($event)"
                        >
                        </code-input>
                      </div>
                    </div>
                    <div class="form-group mb-0">
                      <button
                        class="btn btn-primary pull-right mt-3"
                        type="submit"
                        (click)="createAccount()"
                        [disabled]="!passcodeValue.valid"
                      >
                        Create Account
                      </button>
                      <button class="btn btn-outline-secondary pull-right mr-3 mt-3" type="button" awPreviousStep>
                        Back
                      </button>
                    </div>
                  </div>
                </form>
              </aw-wizard-step>

              <!-- STEP 3 - PASSWORD -->
              <ng-template #loginPassword>
                <aw-wizard-step [navigationSymbol]="{ symbol: '3' }">
                  <form class="theme-form" [formGroup]="passwordValueForm">
                    <ng-template awWizardStepTitle>
                      <p>Register</p>
                    </ng-template>
                    <div class="setup-content" id="step-4">
                      <h4>Great! Now let's keep you secure</h4>
                      <p>Create a strong password to keep your account secure.</p>
                      <div class="form-group">
                        <label class="col-form-label">Password</label>
                        <input
                          class="form-control"
                          formControlName="password"
                          placeholder="Enter password"
                          [type]="revealPassword ? 'text' : 'password'"
                        />
                        <div class="show-hide" (click)="showPassword()" *ngIf="!revealPassword">
                          <span class="show"></span>
                        </div>
                        <div class="show-hide" (click)="showPassword()" *ngIf="revealPassword">
                          <span class="Hide"></span>
                        </div>
                        <ng-container
                          class="invalid"
                          *ngIf="passwordValueForm.get('password').hasError('required') && passwordValueForm.get('password').touched"
                        >
                          <div class="invalid">Password is required</div>
                        </ng-container>
                        <ng-container
                          class="invalid"
                          *ngIf="passwordValueForm.get('password').hasError('minlength') && passwordValueForm.get('password').touched"
                        >
                          <div class="invalid">Password must be 8 characters or more</div>
                        </ng-container>
                        <ng-container
                          class="invalid"
                          *ngIf="passwordValueForm.get('password').hasError('pattern') && passwordValueForm.get('password').touched"
                        >
                          <div class="invalid">Password must contain a number and uppercase letter</div>
                        </ng-container>
                      </div>
                      <div class="form-group">
                        <label class="col-form-label">Confirm Password</label>
                        <input
                          class="form-control"
                          formControlName="confirmPassword"
                          placeholder="Enter password"
                          type="password"
                        />
                        <ng-container
                          class="invalid"
                          *ngIf="passwordValueForm.get('confirmPassword').hasError('required') && passwordValueForm.get('confirmPassword').touched"
                        >
                          <div class="invalid">Password confirmation is required</div>
                        </ng-container>
                        <ng-container
                          class="invalid"
                          *ngIf="passwordValueForm.get('confirmPassword').hasError('minlength') && passwordValueForm.get('confirmPassword').touched"
                        >
                          <div class="invalid">Password confirmation must be 8 characters or more</div>
                        </ng-container>
                        <ng-container
                          class="invalid"
                          *ngIf="passwordValueForm.get('confirmPassword').hasError('pattern') && passwordValueForm.get('confirmPassword').touched"
                        >
                          <div class="invalid">Password must contain a number and uppercase letter</div>
                        </ng-container>
                        <ng-container
                          class="invalid"
                          *ngIf="!passwordsMatch() && passwordValueForm.get('confirmPassword').touched"
                        >
                          <div class="invalid">Passwords do not match</div>
                        </ng-container>
                      </div>
                      <div class="form-group mb-0">
                        <button
                          class="btn btn-primary pull-right mt-3"
                          type="submit"
                          (click)="createAccount()"
                          [disabled]="passwordValueForm.controls['password'].value !== passwordValueForm.controls['confirmPassword'].value"
                        >
                          Create Account
                        </button>
                        <button class="btn btn-outline-secondary pull-right mr-3 mt-3" type="button" awPreviousStep>
                          Back
                        </button>
                      </div>
                    </div>
                  </form>
                </aw-wizard-step>
              </ng-template>
            </aw-wizard>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule } from 'ngx-markdown';
import { ToastrModule } from 'ngx-toastr';
import { LoggerModule } from 'ngx-logger';
import { JwtModule } from '@auth0/angular-jwt';
import { NgSelectModule } from '@ng-select/ng-select';
import { CodeInputModule } from 'angular-code-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CookieService } from 'ngx-cookie-service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MatTabsModule } from '@angular/material/tabs';

// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { ArchwizardModule } from '@nubebytes/angular-archwizard';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { HttpModule } from 'src/app/shared/utils/http/http.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { LoginComponent } from 'src/app/shared/auth/login/login.component';
import { ForgotPasswordComponent } from 'src/app/shared/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from 'src/app/shared/auth/reset-password/reset-password.component';
import { RegisterComponent } from 'src/app/shared/auth/register/register.component';

import { AuthGuard } from 'src/app/shared/guard/auth.guard';
import { SupportPortalGuard } from 'src/app/shared/guard/supportportal.guard';
import { MerchantPortalGuard } from 'src/app/shared/guard/merchantportal.guard';
import { DeveloperPortalGuard } from 'src/app/shared/guard/developerportal.guard';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';

import { environment } from 'src/environment/environment';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, LoginComponent, ForgotPasswordComponent, ResetPasswordComponent, RegisterComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return '';
        },
        allowedDomains: ['localhost:10000'],
        disallowedRoutes: ['//localhost:10000/api/login'],
      },
    }),
    HttpModule.forRoot({
      environment,
    }),
    CodeInputModule,
    NgxIntlTelInputModule,
    LoggerModule.forRoot(environment.ngxLoggerConfig),
    NgxDatatableModule,
    ArchwizardModule,
    CarouselModule,
    MatTabsModule,
    TabsModule.forRoot(),
    MarkdownModule.forRoot(),
  ],
  providers: [
    ApiService,
    AuthService,
    CookieService,
    AuthGuard,
    SupportPortalGuard,
    MerchantPortalGuard,
    DeveloperPortalGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Menu, NavService } from 'src/app/shared/services/nav.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { loadWebConfig } from 'src/app/shared/utils/utils';
import { IWebConfig } from 'src/app/IWebConfig';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent {
  iconSidebar: any;
  menuItems: Menu[];
  url: any;
  fileurl: any;
  // For Horizontal Menu
  margin = 0;
  width = window.innerWidth;
  leftArrowNone = true;
  rightArrowNone = false;
  webconfig: IWebConfig = loadWebConfig();
  orgId = this.webconfig.orgId;
  portalId = this.webconfig.portalId;
  logo = this.webconfig.assets.logo;
  logoWhite = this.webconfig.assets.logoWhite;
  icon = this.webconfig.assets.icon;
  iconWhite = this.webconfig.assets.iconWhite;

  constructor(
    private router: Router,
    public navServices: NavService,
    public layout: LayoutService,
    public authService: AuthService,
  ) {
    this.authService.user.subscribe(() => {
      this.initiateMenuItems();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nav state
  setNavActive(item: Menu) {
    this.menuItems.forEach((menuItem) => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.forEach((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggledNavActive(item: Menu) {
    let found = false;
    this.menuItems.forEach((a) => {
      if (a === item) {
        found = true;
        a.active = !a.active;
      } else {
        if (!a.children) {
          return;
        }
        a.children.forEach((b) => {
          if (b === item) {
            found = true;
            b.active = !b.active;
          }
        });
      }
    });

    if (!found) {
      item.active = true;
    }
  }

  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }

  initiateMenuItems() {
    const items: BehaviorSubject<Menu[]> = this.navServices.menuItems;

    items.subscribe((menuItems) => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.forEach((items) => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) {
              return;
            }
            items.children.forEach((subItems) => {
              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) {
                return;
              }
              subItems.children.forEach((subSubItems) => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }
}

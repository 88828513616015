<!-- Loader starts-->
<div [class.loderhide]="!show" class="loader-wrapper">
  <div class="loader-index"><span></span></div>
  <svg>
    <defs></defs>
    <filter id="goo">
      <fegaussianblur in="SourceGraphic" result="blur" stddeviation="11"></fegaussianblur>
      <fecolormatrix in="blur" result="goo" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"></fecolormatrix>
    </filter>
  </svg>
</div>
<!-- Loader ends-->

import { Routes } from '@angular/router';

import { AuthGuard } from 'src/app/shared/guard/auth.guard';
import { MerchantPortalGuard } from 'src/app/shared/guard/merchantportal.guard';
import { SupportPortalGuard } from 'src/app/shared/guard/supportportal.guard';
import { DeveloperPortalGuard } from 'src/app/shared/guard/developerportal.guard';
import { loadWebConfig } from 'src/app/shared/utils/utils';
import { IWebConfig } from 'src/app/IWebConfig';

export class DeveloperRoutes {
  public static readonly base = '/get-started';
  public static readonly sdk = `${DeveloperRoutes.base}/docs/sdk`;
  public static readonly jwt = `${DeveloperRoutes.base}/docs/jwt`;
  public static readonly appToApp = `${DeveloperRoutes.base}/docs/app-to-app`;
  public static readonly nda = `${DeveloperRoutes.base}/nda`;
}

const merchantRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, MerchantPortalGuard],
    loadChildren: () => import('src/app/portals/merchant/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard, MerchantPortalGuard],
    // data: { roles: [Role.Merchant] },
    loadChildren: () => import('src/app/portals/merchant/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard, MerchantPortalGuard],
    // data: { roles: [Role.Merchant] },
    loadChildren: () => import('src/app/portals/merchant/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'users',
    canActivate: [AuthGuard, MerchantPortalGuard],
    // data: { roles: [Role.Merchant] },
    loadChildren: () => import('src/app/portals/merchant/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'my-devices',
    canActivate: [AuthGuard, MerchantPortalGuard],
    // data: { roles: [Role.Merchant] },
    loadChildren: () => import('src/app/portals/merchant/terminals/terminals.module').then((m) => m.TerminalsModule),
  },
  {
    path: 'transactions',
    canActivate: [AuthGuard, MerchantPortalGuard],
    // data: { roles: [Role.Merchant] },
    loadChildren: () =>
      import('src/app/portals/merchant/transactions/transactions.module').then((m) => m.TransactionsModule),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard, MerchantPortalGuard],
    // data: { roles: [Role.Merchant] },
    loadChildren: () => import('src/app/portals/merchant/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'download-app',
    canActivate: [AuthGuard, MerchantPortalGuard],
    // data: { roles: [Role.Merchant] },
    loadChildren: () =>
      import('src/app/portals/merchant/download-app/download-app.module').then((m) => m.DownloadAppModule),
  },
  {
    path: 'deeplinking',
    canActivate: [AuthGuard, MerchantPortalGuard],
    // data: { roles: [Role.Merchant] },
    loadChildren: () =>
      import('src/app/portals/merchant/deeplinking/deeplinking.module').then((m) => m.DeeplinkingModule),
  },
  {
    path: 'ecommerce',
    canActivate: [AuthGuard, MerchantPortalGuard],
    // data: { roles: [Role.Merchant] },
    loadChildren: () => import('src/app/portals/merchant/ecommerce/ecommerce.module').then((m) => m.ECommerceModule),
  },
  {
    path: 'checkout',
    canActivate: [AuthGuard, MerchantPortalGuard],
    // data: { roles: [Role.Merchant] },
    loadChildren: () => import('src/app/portals/merchant/checkout/checkout.module').then((m) => m.CheckoutModule),
  },
  {
    path: 'tap-on-own',
    canActivate: [AuthGuard, MerchantPortalGuard],
    loadComponent: () =>
      import('src/app/portals/merchant/checkout/order-summary/order-summary.component').then(
        (m) => m.OrderSummaryComponent,
      ),
  },
  {
    path: 'upgrade',
    canActivate: [AuthGuard, MerchantPortalGuard],
    // data: { roles: [Role.Merchant] },
    loadChildren: () => import('src/app/portals/merchant/upgrade/upgrade.module').then((m) => m.UpgradeModule),
  },
  {
    path: 'statements',
    canActivate: [AuthGuard, MerchantPortalGuard],
    // data: { roles: [Role.Merchant] },
    loadChildren: () => import('src/app/portals/merchant/statements/statements.module').then((m) => m.StatementsModule),
  },
  {
    path: 'help-centre',
    canActivate: [AuthGuard, MerchantPortalGuard],
    // data: { roles: [Role.Merchant] },
    loadChildren: () =>
      import('src/app/portals/merchant/help-centre/help-centre.module').then((m) => m.HelpCentreModule),
  },
  {
    path: 'payouts',
    canActivate: [AuthGuard, MerchantPortalGuard],
    // data: { roles: [Role.Merchant] },
    loadChildren: () =>
      import('src/app/portals/merchant/payout-requests/payout-requests.module').then((m) => m.PayoutRequestsModule),
  },
  {
    path: 'build-apk',
    canActivate: [AuthGuard, MerchantPortalGuard],
    // data: { roles: [Role.Merchant] },
    loadChildren: () => import('src/app/portals/merchant/build-apk/build-apk.module').then((m) => m.BuildApkModule),
  },
];

const supportRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, SupportPortalGuard],
    loadChildren: () => import('src/app/portals/support/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard, SupportPortalGuard],
    loadChildren: () => import('src/app/portals/support/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'backoffice',
    canActivate: [AuthGuard, SupportPortalGuard],
    loadChildren: () => import('src/app/portals/support/backoffice/backoffice.module').then((m) => m.BackofficeModule),
  },
];

const developerRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, DeveloperPortalGuard],
    loadChildren: () => import('src/app/portals/developer/hub/hub.module').then((m) => m.HubModule),
  },
  {
    path: 'get-started',
    canActivate: [AuthGuard, DeveloperPortalGuard],
    loadChildren: () => import('src/app/portals/developer/hub/hub.module').then((m) => m.HubModule),
  },
  {
    path: 'backoffice',
    canActivate: [AuthGuard, DeveloperPortalGuard],
    loadChildren: () =>
      import('src/app/portals/developer/backoffice/backoffice.module').then((m) => m.BackofficeModule),
  },
];

// DEFAULT PATHS ARE CONFIGURED IN PAGE-ROUTER MODULE
export const routes: Routes = [
  {
    path: 'router',
    loadChildren: () => import('src/app/base/page-router.module').then((m) => m.PageRouterModule),
  },
];

const webConfig: IWebConfig = loadWebConfig();

switch (webConfig.portalId) {
  case 'merchant':
    routes.push(...merchantRoutes);
    break;

  case 'support':
    routes.push(...supportRoutes);
    break;

  case 'developer':
    routes.push(...developerRoutes);
    break;

  default:
    console.warn(`No routes defined for portalId: ${webConfig.portalId}`);
    break;
}

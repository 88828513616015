import { Component, OnInit } from '@angular/core';

import { Menu, NavService } from 'src/app/shared/services/nav.service';

@Component({
  selector: 'app-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss'],
})
export class BookmarkComponent implements OnInit {
  public menuItems: Menu[];
  public items: Menu[];
  public bookmarkItems: any[] = [];

  public text: string;

  public bookmark = false;
  public bookmarkFlip = false;

  public open = false;
  public searchResult = false;
  public searchResultEmpty = false;

  constructor(public navServices: NavService) {}

  ngOnInit() {
    this.navServices.menuItems.subscribe((menuItems) => {
      this.items = menuItems;
      this.bookmarkItems = [];

      menuItems.forEach((menuItem) => {
        if (menuItem.bookmark) {
          this.bookmarkItems.push(menuItem);
        }
        if (menuItem.children) {
          menuItem.children.forEach((subItems) => {
            if (subItems.bookmark) {
              this.bookmarkItems.push(subItems);
            }
          });
        }
      });
    });
  }

  openBookMark() {
    this.bookmark = !this.bookmark;
  }

  flipBookMark() {
    this.bookmarkFlip = !this.bookmarkFlip;
  }

  searchTerm(term: any) {
    if (!term) {
      this.open = false;
      this.removeFix();
      this.menuItems = [];
      return;
    }

    this.addFix();
    const items = [];
    term = term.toLowerCase();

    const searchItems = (menuItems: Menu[]) => {
      menuItems.forEach((menuItem) => {
        if (!menuItem?.title) return;
        if (menuItem.title.toLowerCase().includes(term) && menuItem.type === 'link') {
          items.push(menuItem);
        }
        if (menuItem.children) {
          menuItem.children.forEach((subItems) => {
            if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
              subItems.icon = menuItem.icon;
              items.push(subItems);
            }
            if (subItems.children) {
              subItems.children.forEach((suSubItems) => {
                if (suSubItems.title.toLowerCase().includes(term)) {
                  suSubItems.icon = menuItem.icon;
                  items.push(suSubItems);
                }
              });
            }
          });
        }
      });
    };

    searchItems(this.items);
    this.checkSearchResultEmpty(items);
    this.menuItems = items;
  }

  checkSearchResultEmpty(items) {
    if (!items.length) {
      this.searchResultEmpty = true;
    } else {
      this.searchResultEmpty = false;
    }
  }

  addFix() {
    this.searchResult = true;
  }

  removeFix() {
    this.searchResult = false;
    this.text = '';
  }

  addToBookmark(items) {
    const index = this.bookmarkItems.indexOf(items);
    if (index === -1 && !items.bookmark) {
      items.bookmark = true;
      this.bookmarkItems.push(items);
      this.text = '';
    } else {
      this.bookmarkItems.splice(index, 1);
      items.bookmark = false;
    }
  }
}

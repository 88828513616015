import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { IWebConfig } from 'src/app/IWebConfig';
import { loadWebConfig } from 'src/app/shared/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  public config = {
    settings: {
      layout: 'Dubai',
      layout_type: 'ltr',
      layout_version: 'light-only',
      sidebar_type: 'default-sidebar',
    },
    color: {
      primary_color: '#FE2F67',
      secondary_color: '#7431D7',
    },
  };
  private webconfig: IWebConfig = loadWebConfig();

  constructor(private logger: NGXLogger) {
    this.init();
  }

  init() {
    try {
      const config = {
        settings: {
          layout: 'Dubai',
          layout_type: 'ltr',
          layout_version: 'light-only',
          sidebar_type: 'default-sidebar',
        },
        color: {
          primary_color: this.webconfig.style.primaryColor,
          secondary_color: this.webconfig.style.secondaryColor,
        },
      };

      if (config.settings.layout_type == 'rtl')
        document.getElementsByTagName('html')[0].setAttribute('dir', config.settings.layout_type);

      document.documentElement.style.setProperty(
        '--primary-gradient',
        `linear-gradient(90deg, ${config.color.primary_color} 0%, ${config.color.secondary_color} 100%)`,
      );
      document.documentElement.style.setProperty(
        '--secondary-gradient',
        `linear-gradient(90deg, ${config.color.primary_color} 40%, ${config.color.secondary_color} 160%)`,
      );

      document.documentElement.style.setProperty('--theme-default', config.color.primary_color);
      document.documentElement.style.setProperty('--theme-secondary', config.color.secondary_color);
    } catch (error) {
      this.logger.error('An error occurred while initializing the layout service:', error);
    }
  }
}

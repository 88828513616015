import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { compactDecrypt, CompactEncrypt } from 'jose';
import { deflateRaw, inflateRaw } from 'pako';
import { NGXLogger } from 'ngx-logger';

import { ApiService } from 'src/app/shared/services/api.service';
import { isExternalUrl } from 'src/app/shared/utils/utils';

const asyncInflateRaw = async (input: Uint8Array) => inflateRaw(input);
const asyncDeflateRaw = async (input: Uint8Array) => deflateRaw(input);

@Injectable({ providedIn: 'root' })
export class EncryptionInterceptor implements HttpInterceptor {
  private encryptionInfo: Promise<{
    keyPair: CryptoKeyPair;
    serverKey: CryptoKey;
    siteId: string;
    serverKeySerial: string;
  }>;

  constructor(
    private apiService: ApiService,
    public logger: NGXLogger,
  ) {
    this.encryptionInfo = this.exchangeKeys();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isLocalHost = window.location.host.includes('localhost');
    const skipEncryption = ['true', '1'].includes(new window.URLSearchParams(window.location.search).get('encrypt'));
    if (isLocalHost && skipEncryption) {
      return next.handle(req);
    }
    if (
      isExternalUrl(req) &&
      !req.url.endsWith('/exchangeKeys') &&
      !req.url.endsWith('/haloDotGoUrl') &&
      !req.url.endsWith('/appSocketServerUrl') &&
      !req.url.endsWith('/register/JWT') &&
      !req.url.endsWith('/getMerchantDocuments')
    ) {
      return from(this.encryptBody(req.body)).pipe(
        concatMap((requestInfo) => {
          return next.handle(
            req.clone({
              body: requestInfo.body,
              headers: req.headers
                .set('x-encryption-serial', requestInfo.serverKeySerial)
                .set('x-site-identifier', requestInfo.siteId)
                .set('x-merchant-portal-secret', 'suchsecretmuchwow'),
            }),
          );
        }),
        concatMap((event) => from(this.decryptBody(event))),
        catchError((error) => {
          return from(this.decryptError(error));
        }),
      );
    } else {
      return next.handle(req);
    }
  }

  async encryptBody(body: any): Promise<{
    body: any;
    serverKeySerial: string;
    siteId: string;
  }> {
    const encInfo = await this.encryptionInfo;
    if (!body) {
      return {
        serverKeySerial: encInfo.serverKeySerial,
        siteId: encInfo.siteId,
        body: null,
      };
    }
    const encoder = new TextEncoder();
    const encryptedBody = await new CompactEncrypt(encoder.encode(JSON.stringify(body)))
      .setProtectedHeader({ alg: 'RSA-OAEP', enc: 'A128GCM', zip: 'DEF' })
      .encrypt(encInfo.serverKey, { deflateRaw: asyncDeflateRaw });

    this.logger.log(encryptedBody);
    return {
      serverKeySerial: encInfo.serverKeySerial,
      siteId: encInfo.siteId,
      body: {
        jwe: encryptedBody,
      },
    };
  }

  async decryptBody(event: HttpEvent<any>): Promise<HttpEvent<any>> {
    if (event instanceof HttpResponse && event.status !== 204) {
      const encInfo = await this.encryptionInfo;
      const decoder = new TextDecoder();
      const { plaintext } = await compactDecrypt(event.body.jwe, encInfo.keyPair.privateKey, {
        inflateRaw: asyncInflateRaw,
      });
      const decryptedBody = JSON.parse(decoder.decode(plaintext));
      this.logger.log(decryptedBody);
      return event.clone({ body: decryptedBody });
    } else {
      return event;
    }
  }

  async decryptError(error: HttpErrorResponse): Promise<HttpEvent<any>> {
    if (error instanceof HttpErrorResponse && error.error?.jwe != null) {
      const encInfo = await this.encryptionInfo;
      const decoder = new TextDecoder();
      const { plaintext } = await compactDecrypt(error.error.jwe, encInfo.keyPair.privateKey, {
        inflateRaw: asyncInflateRaw,
      });
      const decryptedError = JSON.parse(decoder.decode(plaintext));
      this.logger.log(decryptedError);
      //Throwing the error instead of returning since catch expects an exception not a value
      throw new HttpErrorResponse({
        error: decryptedError,
        headers: error.headers,
        status: error.status,
        statusText: error.statusText,
        url: error.url,
      });
    } else {
      throw error;
    }
  }

  async exchangeKeys(): Promise<{
    keyPair: CryptoKeyPair;
    serverKey: CryptoKey;
    siteId: string;
    serverKeySerial: string;
  }> {
    const keyPair = await crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-1',
      },
      false,
      ['decrypt'],
    );

    const publicKey = await crypto.subtle.exportKey('spki', keyPair.publicKey);
    const res = await this.apiService.exchangeKeys(this.arrayBufferToPem(publicKey));

    const siteId = res.siteIdentifier;
    const serverKeySerial = res.keySerial;
    const serverKey = await crypto.subtle.importKey(
      'spki',
      this.base64ToUint8Array(res.serverKey),
      {
        name: 'RSA-OAEP',
        hash: 'SHA-1',
      },
      true,
      ['encrypt'],
    );

    return {
      keyPair,
      serverKey,
      siteId,
      serverKeySerial,
    };
  }

  arrayBufferToPem(buffer: ArrayBuffer) {
    return `-----BEGIN PUBLIC KEY-----\n${btoa(
      String.fromCharCode(...new Uint8Array(buffer)),
    )}\n-----END PUBLIC KEY-----`;
  }

  base64ToUint8Array(str: string): ArrayBuffer {
    const binString = atob(str);
    const buf = new ArrayBuffer(binString.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = binString.length; i < strLen; i++) {
      bufView[i] = binString.charCodeAt(i);
    }
    return buf;
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MerchantDetails } from 'src/app/shared/model/merchant-details.model';
import { Role } from 'src/app/shared/model/user-role.enum';
import { PortalId } from 'src/app/shared/model/portal-id.enum';
import { OrgId } from 'src/app/shared/model/org-id.enum';
import { User } from 'src/app/shared/model/user.model';
import { IWebConfig } from 'src/app/IWebConfig';
import { loadWebConfig } from 'src/app/shared/utils/utils';

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root',
})
export class NavService implements OnDestroy {
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);
  // Search Box
  public search = false;
  // Demo mode
  public demoMode = true;
  // Language
  public language = false;
  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991;
  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth >= 991;
  // Full screen
  public fullScreen = false;
  // Profile complete to show badge on the menu item
  public profileComplete = false;
  public helpCentre = {
    title: 'Help Center',
    type: 'sub',
    icon: 'help-circle',
    children: [
      { title: 'Download App', path: '/download-app', type: 'link' },
      { title: 'App to App', path: '/deeplinking', type: 'link' },
      { title: 'Scan to Tap', path: '/ecommerce', type: 'link' },
      { title: 'Tap on Own', path: '/tap-on-own', type: 'link' },
      { title: 'Push to Pay', path: '/checkout', type: 'link' },
    ],
  };

  public vodacomHelpCentre = {
    title: 'Help Center',
    path: '/help-centre',
    type: 'link',
    icon: 'info',
  };

  public helpCentreDemoMode = {
    title: 'Help Center',
    type: 'sub',
    icon: 'help-circle',
    children: [
      { title: 'Download App', path: '/download-app', type: 'link' },
      { title: 'App to App', path: '/deeplinking', type: 'link' },
      { title: 'Scan to Tap', path: '/ecommerce', type: 'link' },
      { title: 'Push to Pay', path: '/checkout', type: 'link' },
      { title: 'Upgrade', path: '/upgrade', type: 'link' },
    ],
  };

  // (internal only)
  public buildApk = {
    title: 'Build APK',
    path: '/build-apk',
    type: 'link',
    icon: 'smartphone',
  };

  public users = {
    title: 'Users',
    path: '/users',
    type: 'link',
    icon: 'users',
  };

  public disabledUsers = {
    title: 'Users',
    type: 'disabled-link',
    icon: 'users',
  };

  public statementsMenu = {
    title: 'Statements',
    path: '/statements',
    type: 'link',
    icon: 'file',
  };

  public demoStatementsMenu = {
    title: 'Statements',
    type: 'demo-link',
    icon: 'file',
  };

  public merchantPayoutRequestsMenu = {
    title: 'Payout Requests',
    path: '/payouts',
    type: 'link',
    icon: 'dollar-sign',
  };

  public profileMenuMerchant = {
    title: 'Profile',
    path: '/profile/merchant',
    type: 'link',
    icon: 'user',
    badgeType: '',
    badgeValue: '',
  };

  public menuItems = new BehaviorSubject<Menu[]>([]);
  public merchantMenuItems = new BehaviorSubject<Menu[]>([]);
  public supportMenuItems = new BehaviorSubject<Menu[]>([]);
  public developerMenuItems = new BehaviorSubject<Menu[]>([]);

  private webconfig: IWebConfig = loadWebConfig();
  private unsubscriber: Subject<void> = new Subject();
  private menusGenerated = false;
  private menuArraysGenerated = false;
  private MENU_MERCHANT: Menu[];
  private MENU_MERCHANT_DEMO_MODE: Menu[];
  private MENU_SUPPORT: Menu[];
  private MENU_DEVELOPER: Menu[];

  constructor(
    private router: Router,
    private api: ApiService,
    private authService: AuthService,
    private logger: NGXLogger,
  ) {
    this.setScreenWidth(window.innerWidth);
    this.setupResizeListener();
    this.setupRouteChangeListener();
    this.init();
  }

  init() {
    try {
      if (!this.menusGenerated) {
        this.generateMenus();
      }

      this.authService.user.subscribe((user: User) => {
        if (user !== null) this.fetchProfileCompleteness(user);
      });

      this.authService.merchant.subscribe((merchant: MerchantDetails) => {
        if (merchant !== null) {
          merchant.demoMode
            ? this.merchantMenuItems.next(this.MENU_MERCHANT_DEMO_MODE)
            : this.merchantMenuItems.next(this.MENU_MERCHANT);
        }
      });

      if (!this.menuArraysGenerated) {
        this.populateMenuArrays();
      }

      switch (this.webconfig.portalId) {
        case 'merchant':
          this.menuItems = this.merchantMenuItems;
          break;

        case 'support':
          this.menuItems = this.supportMenuItems;
          break;

        case 'developer':
          this.menuItems = this.developerMenuItems;
          break;

        default:
          this.menuItems = this.merchantMenuItems;
          break;
      }
    } catch (error) {
      this.logger.error('An error occurred while initializing the navigation service:', error);
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setupResizeListener(): void {
    fromEvent(window, 'resize')
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
        }
      });
  }

  private setupRouteChangeListener(): void {
    if (window.innerWidth < 991) {
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
      });
    }
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  private fetchProfileCompleteness(user: User) {
    if (user.role === Role.Merchant || user.role === Role.Support) {
      this.api
        .getMerchantCompleteness()
        .then((completeness) => {
          if (completeness.businessProfile.complete) {
            this.profileComplete = true;
          }

          if (completeness.businessAddress.complete) {
            this.profileComplete = true;
          }

          if (completeness.businessContact.complete) {
            this.profileComplete = true;
          }

          if (completeness.bankAccount.complete) {
            this.profileComplete = true;
          }

          if (completeness.externalMerchantSystem?.complete) {
            this.profileComplete = true;
          }

          if (!this.profileComplete && this.webconfig.orgId !== OrgId.Vodacom) {
            this.profileMenuMerchant.badgeType = 'danger';
            this.profileMenuMerchant.badgeValue = '1';
          } else {
            this.profileMenuMerchant.badgeType = '';
            this.profileMenuMerchant.badgeValue = '';
          }
        })
        .catch((e) => {
          this.logger.error(e);
        });
    }
  }

  private generateMenus() {
    this.MENU_MERCHANT = [
      {
        title: 'Dashboard',
        path: '/dashboard',
        type: 'link',
        icon: 'home',
      },
      this.profileMenuMerchant,
      {
        title: 'Transactions',
        path: '/transactions',
        type: 'link',
        icon: 'credit-card',
      },
      this.webconfig.showStatements ? this.statementsMenu : {},
      this.webconfig.showPayoutRequests ? this.merchantPayoutRequestsMenu : {},
      {
        title: 'My Devices',
        path: '/my-devices',
        type: 'link',
        icon: 'tablet',
      },
      this.webconfig.portalId === PortalId.Merchant && this.webconfig.orgId === OrgId.Vodacom
        ? this.disabledUsers
        : this.users,
      this.webconfig.portalId === PortalId.Merchant && this.webconfig.orgId === OrgId.Vodacom
        ? this.vodacomHelpCentre
        : {},
      this.webconfig.portalId === PortalId.Merchant && this.webconfig.orgId === OrgId.Go ? this.helpCentre : {},
    ];

    this.MENU_MERCHANT_DEMO_MODE = [
      {
        title: 'Dashboard',
        path: '/dashboard',
        type: 'link',
        icon: 'home',
      },
      this.profileMenuMerchant,
      {
        title: 'Transactions',
        path: '/transactions',
        type: 'link',
        icon: 'credit-card',
      },
      this.webconfig.showStatements &&
      this.webconfig.portalId === PortalId.Merchant &&
      this.webconfig.orgId === OrgId.Go
        ? this.demoStatementsMenu
        : {},
      this.webconfig.showPayoutRequests ? this.merchantPayoutRequestsMenu : {},
      {
        title: 'My Devices',
        path: '/my-devices',
        type: 'link',
        icon: 'tablet',
      },
      {
        title: 'Users',
        path: '/users',
        type: 'link',
        icon: 'users',
      },
      this.webconfig.portalId === PortalId.Merchant && this.webconfig.orgId === OrgId.Go ? this.helpCentreDemoMode : {},
    ];

    this.MENU_SUPPORT = [
      {
        title: 'Dashboard',
        path: '/dashboard',
        type: 'link',
        icon: 'home',
      },
      {
        title: 'Back Office',
        path: '/backoffice',
        type: 'link',
        icon: 'archive',
      },
    ];

    this.MENU_DEVELOPER = [
      {
        title: 'Get Started',
        path: '/get-started',
        type: 'link',
        icon: 'code',
      },
      {
        title: 'Back Office',
        path: '/backoffice',
        type: 'link',
        icon: 'archive',
      },
    ];

    this.menusGenerated = true;
  }

  private populateMenuArrays() {
    this.merchantMenuItems = new BehaviorSubject<Menu[]>(this.MENU_MERCHANT);
    this.supportMenuItems = new BehaviorSubject<Menu[]>(this.MENU_SUPPORT);
    this.developerMenuItems = new BehaviorSubject<Menu[]>(this.MENU_DEVELOPER);

    this.menuArraysGenerated = true;
  }
}

<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button (click)="activeModal.dismiss('cross')" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="formGroup" [ngClass]="{'was-validated': validate}" class="needs-validation" novalidate>
  <div class="modal-body">
    <p *ngIf="subtitle">{{ subtitle }}</p>
    <div *ngFor="let field of fields; let i = index">
      <div *ngIf="!field.columnAlign; else columnTemplate">
        <div class="field">
          <label>{{ field.label }}</label>
          <input
            class="form-control"
            formControlName="{{ field.id }}"
            required="{{ field.required }}"
            type="{{ field.type }}"
          />
          <ng-container
            *ngIf="formGroup.get(field.id).touched && formGroup.get(field.id).status === 'INVALID'"
            class="invalid"
          >
            <div class="invalid">{{ field.error }}</div>
          </ng-container>
        </div>
      </div>
      <ng-template #columnTemplate>
        <div *ngIf="i%2===0 && fields[i].columnAlign && fields[i+1].columnAlign" class="field-cols">
          <div class="field {{fields[i].columnAlign}}">
            <label>{{ fields[i].label }}</label>
            <input
              class="form-control"
              formControlName="{{ fields[i].id }}"
              required="{{ fields[i].required }}"
              type="{{ fields[i].type }}"
            />
            <ng-container
              *ngIf="formGroup.get(fields[i].id).touched && formGroup.get(fields[i].id).status === 'INVALID'"
              class="invalid"
            >
              <div class="invalid">{{ fields[i].error }}</div>
            </ng-container>
          </div>
          <div class="field {{fields[i+1].columnAlign}}">
            <label>{{ fields[i + 1].label }}</label>
            <input
              class="form-control"
              formControlName="{{ fields[i+1].id }}"
              required="{{ fields[i+1].required }}"
              type="{{ fields[i+1].type }}"
            />
            <ng-container
              *ngIf="formGroup.get(fields[i+1].id).touched && formGroup.get(fields[i+1].id).status === 'INVALID'"
              class="invalid"
            >
              <div class="invalid">{{ fields[i + 1].error }}</div>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="onClickTertiary()" *ngIf="tertiaryBtnLabel" class="btn default" type="button">
      {{ tertiaryBtnLabel }}
    </button>
    <div class="align-right">
      <button (click)="onClickSecondary()" *ngIf="secondaryBtnLabel" class="btn btn-light mr-2" type="button">
        {{ secondaryBtnLabel }}
      </button>
      <button (click)="onClickPrimary()" *ngIf="primaryBtnLabel" class="btn btn-primary" type="button">
        {{ primaryBtnLabel }}
      </button>
    </div>
  </div>
</form>

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as feather from 'feather-icons';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { fadeInAnimation } from 'src/app/shared/animations/router-animation';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [fadeInAnimation],
})
export class ContentComponent implements OnInit, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    public navServices: NavService,
    public layout: LayoutService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.layout.config.settings.layout = params.layout ? params.layout : this.layout.config.settings.layout;
    });
  }

  get layoutClass() {
    const { settings } = this.layout.config;
    const { horizontal } = this.navServices;

    switch (settings.layout) {
      case 'Dubai':
        return 'compact-wrapper';
      case 'London':
        return 'only-body';
      case 'Seoul':
        return 'compact-wrapper modern-type';
      case 'LosAngeles':
        return horizontal ? 'horizontal-wrapper material-type' : 'compact-wrapper material-type';
      case 'Paris':
        return 'compact-wrapper dark-sidebar';
      case 'Tokyo':
        return 'compact-sidebar';
      case 'Madrid':
        return 'compact-wrapper color-sidebar';
      case 'Moscow':
        return 'compact-sidebar compact-small';
      case 'NewYork':
        return 'compact-wrapper box-layout';
      case 'Singapore':
        return horizontal ? 'horizontal-wrapper enterprice-type' : 'compact-wrapper enterprice-type';
      case 'Rome':
        return 'compact-sidebar compact-small material-icon';
      case 'Barcelona':
        return horizontal
          ? 'horizontal-wrapper enterprice-type advance-layout'
          : 'compact-wrapper enterprice-type advance-layout';
      default:
        return '';
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  ngOnInit() {}
}

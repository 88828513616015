import { InjectionToken } from '@angular/core';
import { NgxLoggerLevel } from 'ngx-logger';

export interface EnvironmentConfig {
  environment: {
    production: boolean;
    ngxLoggerConfig: {
      level: NgxLoggerLevel;
      disableConsoleLogging: boolean;
    };
    darkMode: boolean;
    navLanguages: boolean;
    navSearch: boolean;
    navSettings: boolean;
  };
}

export const ENV_CONFIG = new InjectionToken<EnvironmentConfig>('EnvironmentConfig');

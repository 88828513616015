/* eslint-disable prettier/prettier */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input-gg';
import { WizardComponent } from '@nubebytes/angular-archwizard';

import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { loadWebConfig, getCarouselImages, PASSWORD_PATTERN } from 'src/app/shared/utils/utils';
import { Country } from 'src/app/shared/model/country.model';
import { LoginType, AccountType, OTPChannelType } from 'src/app/shared/model/auth-channels';
import { IWebConfig } from 'src/app/IWebConfig';

const TIMEOUT_IN_SECONDS = 60;

@Component({
  selector: 'app-merchant-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [style({ transform: 'translateX(100%)' }), animate('300ms ease-out')]),
      transition('* => void', [animate('300ms ease-out', style({ transform: 'translateX(-100%)' }))]),
    ]),
  ],
})
export class RegisterComponent implements OnInit {
  @ViewChild(WizardComponent)
  public wizard: WizardComponent;

  private webconfig: IWebConfig = loadWebConfig();
  public orgId: string = this.webconfig.orgId;
  public logo = this.webconfig.assets.logo;
  public logoDark = this.webconfig.assets.logoWhite;
  public termsAndConditionsUrl = this.webconfig.assets.termsAndConditionsUrl;
  public apkPackageName = this.webconfig.apkPackageName;
  public loginType: string = this.webconfig.loginType;
  public accountType: string = this.webconfig.accountType;

  public timeLeft: number = TIMEOUT_IN_SECONDS;
  public interval = 0;

  public verifyGUID: string = null;
  public otpForm: FormGroup = new FormGroup({});

  public isShortPassword = this.loginType === LoginType.Pin;
  public revealPassword = false;

  public passwordDetailsForm: FormGroup = new FormGroup({});
  public detailsForm: FormGroup = new FormGroup({});
  public chosenForm: FormGroup = new FormGroup({});

  public passwordValueForm: FormGroup = new FormGroup({});
  public passcodeValue = {
    passcode: '',
    confirmPasscode: '',
    valid: false,
  };

  public channelType: OTPChannelType = OTPChannelType.Email;
  public channelTypeValue: string;
  public countryList: Country[] = [];
  public separateDialCode = false;
  public preferredCountries: CountryISO[] = [CountryISO.SouthAfrica, CountryISO.UnitedKingdom, CountryISO.UnitedStates];
  public backgroundImage = getCarouselImages();
  public SearchCountryField = SearchCountryField;
  public CountryISO = CountryISO;
  public PhoneNumberFormat = PhoneNumberFormat;

  constructor(
    public authService: AuthService,
    private logger: NGXLogger,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
  ) {
    this.getTermsAndConditions();
    this.api.getCountries().then((response) => {
      this.countryList = response;
    });

    this.detailsForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      entityName: ['', Validators.required],
      country: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: [undefined],
      usePhoneNumberCheckbox: [false],
      confirmCheckbox: [false, Validators.requiredTrue],
    });

    this.passwordValueForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(PASSWORD_PATTERN)]],
      confirmPassword: ['', Validators.required, Validators.minLength(8), Validators.pattern(PASSWORD_PATTERN)],
    });

    this.otpForm = this.formBuilder.group({
      otpNumber: [''],
    });
  }
  ngOnInit(): void {
    this.authService.removeLoginCredentials();
  }

  private async getTermsAndConditions() {
    try {
      const response = await this.api.getBranding(this.apkPackageName);
      this.termsAndConditionsUrl = response.termsAndConditionsUrl;
    } catch (e) {
      this.logger.error('Failed to get branding:', e);
    }
  }

  private async createMerchant(data: any) {
    try {
      await this.api.createMerchant(data);
      this.toaster.success('Successfully created your merchant account');
    } catch (e) {
      this.logger.error('Error creating merchant:', e);
      this.toaster.error('Error creating merchant');
    }
  }

  private async createIntegrator(data: any) {
    try {
      await this.api.createIntegrator(data);
      this.toaster.success('Successfully created your integrator account');
    } catch (e) {
      this.logger.error('Error creating integrator:', e);
      this.toaster.error('Error creating integrator');
    }
  }

  private async getMerchantObject(): Promise<any> {
    const email = this.detailsForm.controls.email.value.toLowerCase().trim();
    const mobileNumber = this.detailsForm.controls.mobileNumber.value?.e164Number;

    const merchant = {
      username: this.channelType == OTPChannelType.SMS ? mobileNumber : email,
      entityName: this.detailsForm.controls.entityName.value,
      contactName: `${this.detailsForm.controls.firstName.value} ${this.detailsForm.controls.lastName.value}`,
      contactEmail: email,
      password:
        this.loginType === LoginType.Pin ? this.passcodeValue.passcode : this.passwordValueForm.controls.password.value,
      verifyGUID: this.verifyGUID,
      verificationChannel: this.channelType,
      acquirerDetails: {
        apkPackageName: this.apkPackageName,
      },
    };

    if (this.channelType == OTPChannelType.SMS) {
      merchant['contactNumber'] = mobileNumber;
    }

    return merchant;
  }

  private async getIntegratorObject(): Promise<any> {
    const email = this.detailsForm.controls.email.value.toLowerCase().trim();
    const mobileNumber = this.detailsForm.controls.mobileNumber.value?.e164Number;

    const integrator = {
      username: this.channelType == OTPChannelType.SMS ? mobileNumber : email,
      entityName: this.detailsForm.controls.entityName.value,
      contactName: `${this.detailsForm.controls.firstName.value} ${this.detailsForm.controls.lastName.value}`,
      contactEmail: email,
      password:
        this.loginType === LoginType.Pin ? this.passcodeValue.passcode : this.passwordValueForm.controls.password.value,
      verifyGUID: this.verifyGUID,
      verificationChannel: this.channelType,
      appName: this.apkPackageName,
      region: this.detailsForm.controls.country.value,
    };

    if (this.channelType == OTPChannelType.SMS) {
      integrator['contactNumber'] = mobileNumber;
    }

    return integrator;
  }

  private getMobileNumberAndEmail(): { mobileNumber: string; email: string } {
    if (!this.detailsForm.valid) {
      this.logger.error('Please fill in all the required fields');
    }

    const email = this.detailsForm.controls.email.value.toLowerCase().trim();
    const mobileNumber = this.detailsForm.controls.mobileNumber.value?.e164Number;

    return { mobileNumber, email };
  }

  private passwordValid() {
    if (this.loginType === LoginType.Pin) {
      return this.passcodeValue.passcode === this.passcodeValue.confirmPasscode;
    } else if (this.loginType === LoginType.Password) {
      return this.passwordValueForm.controls.password.value === this.passwordValueForm.controls.confirmPassword.value;
    }

    return false;
  }

  private startTimer() {
    this.stopTimer();
    this.timeLeft = TIMEOUT_IN_SECONDS;
    this.interval = window.setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.handleTimeout();
      }
    }, 1000);
  }

  private stopTimer() {
    clearInterval(this.interval);
  }

  private handleTimeout() {
    this.stopTimer();
    this.timeLeft = TIMEOUT_IN_SECONDS;
    this.verifyGUID = null;
    this.resetOtpForm();
  }

  private resetOtpForm() {
    const otpNumberControl = this.otpForm.controls.otpNumber;
    otpNumberControl.clearValidators();
    otpNumberControl.updateValueAndValidity();
    otpNumberControl.setValue('');
  }

  private async loginAfterRegistration() {
    let account: any;

    if (this.accountType === AccountType.Merchant) {
      account = await this.getMerchantObject();
    } else if (this.accountType === AccountType.Integrator) {
      account = await this.getIntegratorObject();
    }

    await this.authService.login(account.username, account.password);

    const user = this.authService.userValue;
    if (user) {
      this.router.navigate(['/router']);
    } else {
      this.router.navigate(['/']);
    }
  }

  public openTermsAndConditions() {
    window.open(this.termsAndConditionsUrl, '_blank');
  }

  public async validateUniqueness() {
    if (!this.detailsForm.valid) {
      this.logger.error('Please fill in all the required fields');
      return;
    }

    const email = this.detailsForm.controls.email.value?.toLowerCase().trim();
    const mobileNumber = this.detailsForm.controls.mobileNumber.value?.e164Number;
    const entityName = this.detailsForm.controls.entityName.value.trim();

    try {
      const [emailAvailable, mobileNumberAvailable, merchantNameAvailable, usernameAvailable] = await Promise.allSettled([
        this.api.isEmailAvailable(email),
        this.api.isMobileNumberAvailable(mobileNumber),
        this.api.isMerchantNameAvailable(entityName),
        this.api.isUsernameAvailable(this.channelType == OTPChannelType.SMS ? mobileNumber : email),
      ]) as PromiseFulfilledResult<{ available: boolean }>[];
      
      if (!emailAvailable?.value?.available) {
        this.toaster.error('You must sign up with a unique email address.', 'Account already exists');
        this.logger.error('Failed to validate uniqueness:', 'Email already exists', email);
        return;
      }

      if (!mobileNumberAvailable?.value?.available) {
        this.toaster.error('You must sign up with a unique phone number.', 'Account already exists');
        this.logger.error('Failed to validate uniqueness:', 'Mobile number already exists', mobileNumber);
        return;
      }

      if (!usernameAvailable?.value?.available) {
        this.toaster.error('You must sign up with a unique username.', 'Account already exists');
        this.logger.error('Failed to validate uniqueness:', 'Username already exists', email);
        return;
      }

      if (!merchantNameAvailable?.value?.available) {
        this.toaster.error('You must sign up with a unique business name.', 'Account already exists');
        this.logger.error('Failed to validate uniqueness:', 'Business name already exists', entityName);
        return;
      }

      this.getOtp();
    } catch (error) {
      this.logger.error('Failed to validate uniqueness:', error.message);
      this.toaster.error('Failed to validate uniqueness');
    }
  }

  public async getOtp(resend = false) {
    const { mobileNumber, email } = this.getMobileNumberAndEmail();

    try {
      const response = await this.api.generateOtp({
        channel: this.channelType,
        address: this.channelType == OTPChannelType.Email ? email : mobileNumber,
        apkPackageName: this.apkPackageName,
      });

      this.toaster.success('Successfully sent you an OTP');
      this.verifyGUID = response.id;

      this.startTimer();
      if (!resend) {
        this.wizard.goToNextStep();
      }
    } catch (e) {
      this.logger.error('Failed to send OTP:', e);
      this.toaster.error('Failed to send OTP:', e.message);
    }
  }

  public async verifyOtp(code: string) {
    const { mobileNumber, email } = this.getMobileNumberAndEmail();

    try {
      await this.api.verifyOtp({
        id: this.verifyGUID,
        code: code,
        channel: this.channelType,
        address: this.channelType == OTPChannelType.Email ? email : mobileNumber,
      });

      this.stopTimer();
      this.toaster.success(`Successfully verified your OTP`);
      this.wizard.goToNextStep();
    } catch (e) {
      this.logger.error('Failed to verify OTP:', e);
      this.toaster.error('Failed to verify OTP');
    }
  }

  public async createAccount() {
    if (this.detailsForm.invalid) {
      this.logger.error('Please fill in all the required fields');
      return;
    }

    if (!this.passwordValid()) {
      this.logger.error('Passwords do not match');
      return;
    }

    try {
      if (this.accountType === AccountType.Merchant) {
        const merchant = await this.getMerchantObject();
        await this.createMerchant(merchant);
      } else if (this.accountType === AccountType.Integrator) {
        const integrator = await this.getIntegratorObject();
        await this.createIntegrator(integrator);
      } else {
        this.logger.error('Invalid account type: ', this.accountType);
        return;
      }

      await this.loginAfterRegistration();
    } catch (error) {
      this.logger.error(`Error creating ${AccountType.Merchant} account:`, error);
      this.toaster.error(`Error creating ${AccountType.Merchant} account`);
    }
  }

  public onUsePhoneNumberSelected(event: any) {
    if (!this.detailsForm.valid) {
      this.logger.error('Please fill in all the required fields');
    }

    const usePhoneNumber = event.target.checked;
    const mobileNumberControl = this.detailsForm.get('mobileNumber');

    this.channelType = usePhoneNumber ? OTPChannelType.SMS : OTPChannelType.Email;

    if (usePhoneNumber) {
      mobileNumberControl.setValidators(Validators.required);
    } else {
      mobileNumberControl.clearValidators();
    }

    mobileNumberControl.updateValueAndValidity();
  }

  public enterPasscode(password: string) {
    this.passcodeValue.passcode = password;
    this.passcodeValue.valid = this.passcodeValue.passcode === this.passcodeValue.confirmPasscode;
  }

  public enterConfirmPasscode(password: string) {
    this.passcodeValue.confirmPasscode = password;
    this.passcodeValue.valid = this.passcodeValue.passcode === this.passcodeValue.confirmPasscode;
  }

  public showPassword() {
    this.revealPassword = !this.revealPassword;
  }

  public passwordsMatch(): boolean {
    const password = this.passwordValueForm.get('password').value;
    const confirmPassword = this.passwordValueForm.get('confirmPassword').value;
    return password === confirmPassword;
  }
}

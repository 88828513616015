import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { loadWebConfig } from 'src/app/shared/utils/utils';
import { IWebConfig } from 'src/app/IWebConfig';
import { PortalId } from 'src/app/shared/model/portal-id.enum';

@Injectable({
  providedIn: 'root',
})
export class MerchantPortalGuard implements CanActivate {
  private webconfig: IWebConfig = loadWebConfig();

  constructor(private toaster: ToastrService) {}

  async canActivate() {
    const isAllowed = this.webconfig.portalId === PortalId.Merchant;

    if (!isAllowed) {
      this.toaster.error('You do not have permission to access this page');
    }

    return isAllowed;
  }
}

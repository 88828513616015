import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';

import { PASSWORD_PATTERN, PIN_PATTERN, getCarouselImages, loadWebConfig } from 'src/app/shared/utils/utils';
import { ApiService } from 'src/app/shared/services/api.service';
import { MustMatch } from 'src/app/shared/validators/passwordMatch';
import { IWebConfig } from 'src/app/IWebConfig';
import { LoginType } from 'src/app/shared/model/auth-channels';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  private webconfig: IWebConfig = loadWebConfig();
  public logo = this.webconfig.assets.logo;
  public logoWhite = this.webconfig.assets.logoWhite;
  public portalId: string = this.webconfig.portalId;
  public loginType: string = this.webconfig.loginType.toLocaleLowerCase();

  private password: string;
  private confirmPassword: string;

  public loginRoute = 'auth/login';
  public backgroundImage = getCarouselImages();

  public emailForm: FormGroup;
  public passwordForm: FormGroup;
  public passwordFormValid = false;
  public passwordRequested = false;
  public passwordToken: string = null;
  public revealPassword = false;

  constructor(
    public api: ApiService,
    private logger: NGXLogger,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService,
  ) {}

  public ngOnInit(): void {
    this.passwordToken = this.route.snapshot.paramMap.get('token');
    this.logger.debug(this.passwordToken);
    this.createForms();
  }

  public async resetPassword() {
    const passwordValue: string =
      this.loginType === LoginType.Pin ? this.password : this.passwordForm.controls.password.value;

    try {
      await this.api.resetPasswordWithToken(this.passwordToken, passwordValue);
      this.passwordRequested = true;
      this.toaster.success(`Successfully updated your ${this.loginType}`);
      this.router.navigate([this.loginRoute]);
    } catch (error) {
      this.toaster.error(error.message);
      this.logger.debug('Failed to send instructions to reset password', error);
    }
  }

  public onPasswordChanged(password: string) {
    this.password = password;
    if (password.length < 4) this.passwordFormValid = false;
  }

  public onConfirmPasswordChanged(password: string) {
    this.confirmPassword = password;
    if (password.length < 4) this.passwordFormValid = false;
  }

  public onPasswordCompleted(password: string) {
    this.password = password;
    this.validatePassword();
  }

  public onConfirmPasswordCompleted(password: string) {
    this.confirmPassword = password;
    this.validatePassword();
  }

  public validatePassword() {
    if (this.password === this.confirmPassword) this.passwordFormValid = true;
  }

  public showPassword() {
    this.revealPassword = !this.revealPassword;
  }

  private createForms() {
    this.loginType === 'pin'
      ? (this.passwordForm = this.formBuilder.group(
          {
            password: [
              '',
              [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(PIN_PATTERN)],
            ],
            confirmPassword: [
              '',
              [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(PIN_PATTERN)],
            ],
          },
          {
            validators: MustMatch('password', 'confirmPassword'),
          },
        ))
      : (this.passwordForm = this.formBuilder.group(
          {
            password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(PASSWORD_PATTERN)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(PASSWORD_PATTERN)]],
          },
          {
            validators: MustMatch('password', 'confirmPassword'),
          },
        ));
  }
}

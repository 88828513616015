<div (click)="openBookMark()" class="notification-box">
  <app-feather-icons [icon]="'star'"></app-feather-icons>
</div>
<div [class.active]="bookmarkFlip || bookmark" class="onhover-show-div bookmark-flip">
  <div class="flip-card">
    <div [class.flipped]="bookmarkFlip" class="flip-card-inner">
      <div class="front">
        <ul class="droplet-dropdown bookmark-dropdown">
          <li class="gradient-primary">
            <app-feather-icons [icon]="'star'"></app-feather-icons>
            <h6 class="f-18 mb-0">Bookmark</h6>
          </li>
          <li class="custom-scrollbar">
            <div class="row">
              <div *ngFor="let item of bookmarkItems" class="col-4 text-center">
                <a [ngbTooltip]="item?.title" [routerLink]="item?.path" placement="top" routerLinkActive="item?.active">
                  <app-feather-icons [icon]="item?.icon"></app-feather-icons>
                </a>
              </div>
            </div>
          </li>
          <li (click)="flipBookMark()" class="text-center">
            <button class="flip-btn" id="flip-btn">Add New Bookmark</button>
          </li>
        </ul>
      </div>
      <div class="back">
        <ul>
          <li>
            <div class="droplet-dropdown bookmark-dropdown flip-back-content">
              <input
                (keyup)="searchTerm(text)"
                [(ngModel)]="text"
                [ngModelOptions]="{standalone: true}"
                placeholder="search..."
                type="text"
              />
              <div
                *ngIf="menuItems?.length"
                [class.is-open]="searchResult"
                class="Typeahead-menu custom-scrollbar"
                id="boomark-search-outer"
              >
                <div *ngFor="let item of menuItems | slice:0:8" class="ProfileCard u-cf">
                  <div class="ProfileCard-avatar">
                    <app-feather-icons [icon]="item.icon" class="header-search"></app-feather-icons>
                  </div>
                  <div class="ProfileCard-details">
                    <div class="ProfileCard-realName">
                      <a (click)="removeFix()" [routerLink]="item?.path" class="realname">{{item?.title}}</a>
                      <span class="pull-right">
                        <a (click)="addToBookmark(item)" href="javacript:void(0)">
                          <i [class.starred]="item.bookmark" class="fa fa-star-o f-18 mt-1"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div [class.is-open]="searchResultEmpty" class="Typeahead-menu">
                <div class="tt-dataset tt-dataset-0">
                  <div class="EmptyMessage">No results found</div>
                </div>
              </div>
            </div>
          </li>
          <li (click)="flipBookMark()">
            <button class="d-block flip-back" id="flip-back">Back</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div (click)="toggleCart()" class="cart-box">
  <app-feather-icons [icon]="'shopping-cart'"></app-feather-icons>
  <span class="badge badge-pill badge-primary">2</span>
</div>
<ul [class.active]="openCart" class="cart-dropdown onhover-show-div">
  <li>
    <h6 class="mb-0 f-20">Shoping Bag</h6>
    <app-feather-icons [icon]="'shopping-cart'"></app-feather-icons>
  </li>
  <li class="mt-0">
    <div class="media">
      <img alt="" class="img-fluid rounded-circle mr-3 img-60" src="assets/images/ecommerce/01.jpg" />
      <div class="media-body">
        <span>V-Neck Shawl Collar Woman's Solid T-Shirt</span>
        <p>Yellow(#fcb102)</p>
        <div class="qty-box">
          <div class="input-group">
            <span class="input-group-prepend">
              <button class="btn quantity-left-minus" data-field="" data-type="minus" type="button">
                <i data-feather="minus"></i>
              </button>
            </span>
            <input class="form-control input-number" name="quantity" type="text" value="1" />
            <span class="input-group-prepend">
              <button class="btn quantity-right-plus" data-field="" data-type="plus" type="button">
                <i data-feather="plus"></i>
              </button>
            </span>
          </div>
        </div>
        <h6 class="text-right text-muted">$299.00</h6>
      </div>
      <div class="close-circle">
        <a href="#">
          <app-feather-icons [icon]="'x'"></app-feather-icons>
        </a>
      </div>
    </div>
  </li>
  <li class="mt-0">
    <div class="media">
      <img alt="" class="img-fluid rounded-circle mr-3 img-60" src="assets/images/ecommerce/03.jpg" />
      <div class="media-body">
        <span>V-Neck Shawl Collar Woman's Solid T-Shirt</span>
        <p>Yellow(#fcb102)</p>
        <div class="qty-box">
          <div class="input-group">
            <span class="input-group-prepend">
              <button class="btn quantity-left-minus" data-type="minus" type="button">
                <i data-feather="minus"></i>
              </button>
            </span>
            <input class="form-control input-number" name="quantity" type="text" value="1" />
            <span class="input-group-prepend">
              <button class="btn quantity-right-plus" data-type="plus" type="button">
                <i data-feather="plus"></i>
              </button>
            </span>
          </div>
        </div>
        <h6 class="text-right text-muted">$299.00</h6>
      </div>
      <div class="close-circle">
        <a href="#">
          <app-feather-icons [icon]="'x'"></app-feather-icons>
        </a>
      </div>
    </div>
  </li>
  <li>
    <div class="total">
      <h6 class="mb-2 mt-0 text-muted">Order Total : <span class="f-right f-20">$598.00</span></h6>
    </div>
  </li>
  <li>
    <a class="btn btn-block btn-primary view-cart" href="#"> Go to shoping bag </a>
    <a class="btn btn-block btn-secondary view-cart" href="#">Checkout</a>
  </li>
</ul>

<div [class.close_icon]="navServices.collapseSidebar" class="page-header">
  <div class="header-wrapper row m-0">
    <app-search></app-search>
    <div class="header-logo-wrapper">
      <div class="logo-wrapper">
        <a routerLink="/">
          <img alt="" class="img-fluid for-light" src="{{logo}}" />
          <img alt="" class="img-fluid for-dark" src="{{logo}}" />
        </a>
      </div>
      <div (click)="sidebarToggle()" class="toggle-sidebar">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
    <div class="left-header col horizontal-wrapper pl-0"></div>
    <div class="nav-right col-8 pull-right right-header p-0">
      <ul class="nav-menus">
        <div
          *ngIf="orgId === OrgId.Go && portalId === PortalId.Merchant && demoMode && userRole !== roleEnum.Admin"
          class="text-right upgrade-btn"
        >
          <a *ngIf="!dark" [routerLink]="['/upgrade']" class="btn btn-outline-dark-2x">
            <i class="fa fa-diamond mr-2"></i>Upgrade
          </a>
          <a *ngIf="dark" [routerLink]="['/upgrade']" class="btn btn-outline-light-2x">
            <i class="fa fa-diamond mr-2"></i>Upgrade
          </a>
        </div>
        <li class="profile-nav onhover-dropdown p-0" onClick="return true">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>

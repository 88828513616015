<!-- Page Sidebar Start-->
<div class="logo-wrapper">
  <a routerLink="/">
    <img alt="" class="img-fluid for-light full-logo-img" src="{{logo}}" />
    <img alt="" class="img-fluid for-dark full-logo-img" src="{{logoWhite}}" />
  </a>
  <div (click)="sidebarToggle()" class="toggle-sidebar">
    <app-feather-icons [icon]="'grid'" class="status_toggle middle sidebar-toggle"></app-feather-icons>
  </div>
</div>
<div (click)="sidebarToggle()" class="logo-icon-wrapper">
  <a href="javascript:void(0)">
    <img alt="" class="img-fluid for-light logo-icon-img" src="{{icon}}" />
    <img alt="" class="img-fluid for-dark logo-icon-img" src="{{iconWhite}}" />
  </a>
</div>
<nav class="sidebar-main">
  <div (click)="scrollToLeft()" [class.d-none]="leftArrowNone" class="left-arrow" id="left-arrow">
    <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
  </div>
  <div
    [ngStyle]="{ marginLeft : this.layout.config.settings.layout === 'Rome' || 'Singapore' || 'Barcelona' ? margin + 'px' : '0px'}"
    id="sidebar-menu"
  >
    <ul class="sidebar-links custom-scrollbar">
      <li class="back-btn">
        <a href="javascript:void(0)">
          <img alt="" class="img-fluid logo-icon-img" src="{{icon}}" />
        </a>
        <div (click)="sidebarToggle()" class="mobile-back text-right">
          <span>Back</span>
          <i aria-hidden="true" class="fa fa-angle-right pl-2"></i>
        </div>
      </li>

      <li
        *ngFor="let menuItem of menuItems"
        [class]="menuItem.headTitle1 ? 'sidebar-main-title' : 'sidebar-list'"
        [ngClass]="{active: menuItem.active}"
      >
        <div *ngIf="menuItem.headTitle1">
          <h6 class="lan-1">{{menuItem.headTitle1 | translate}}</h6>
          <p class="lan-2">{{menuItem.headTitle2 | translate}}</p>
        </div>

        <label *ngIf="menuItem.badgeType && menuItem.badgeValue" class="badge badge-{{menuItem.badgeType}}"
          >{{menuItem.badgeValue}}</label
        >
        <!-- Sub -->
        <a
          (click)="toggledNavActive(menuItem)"
          *ngIf="menuItem.type === 'sub'"
          [class.link-nav]="!menuItem.children"
          [ngClass]="{active: menuItem.active}"
          class="sidebar-link sidebar-title"
          href="javascript:void(0)"
        >
          <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
          <span>{{menuItem.title | translate}}</span>
          <div class="according-menu">
            <i *ngIf="menuItem.children" class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right"></i>
          </div>
        </a>
        <!-- Demo App Link -->
        <a *ngIf="menuItem.type === 'demo-link'" class="sidebar-link sidebar-title demo-link">
          <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
          <span>{{menuItem.title | translate}}</span>
          <div class="upgrade-icon">
            <i class="fa fa-diamond pull-right ml-1" ngbTooltip="{{ 'SIDEBAR.UPGRADE_TOOLTIP' | translate }}"> </i>
          </div>
        </a>
        <!-- Disabled App Link -->
        <a *ngIf="menuItem.type === 'disabled-link'" class="sidebar-link sidebar-title disabled-link">
          <app-feather-icons [icon]="menuItem.icon" class="disabled"></app-feather-icons>
          <span>{{menuItem.title | translate}}</span>
          <div class="according-menu">
            <i *ngIf="menuItem.children" class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right"></i>
          </div>
        </a>
        <!-- Link -->
        <a
          *ngIf="menuItem.type === 'link'"
          [class.link-nav]="!menuItem.children"
          [ngClass]="{active: menuItem.active}"
          [routerLink]="!menuItem.type ? null : [menuItem.path]"
          class="sidebar-link sidebar-title"
          routerLinkActive="active"
        >
          <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
          <span>{{menuItem.title | translate}}</span>
          <div class="according-menu">
            <i *ngIf="menuItem.children" class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right"></i>
          </div>
        </a>
        <!-- External Link -->
        <a
          *ngIf="menuItem.type === 'extLink'"
          [class.link-nav]="!menuItem.children"
          [ngClass]="{active: menuItem.active}"
          class="sidebar-link sidebar-title"
          href="{{ !menuItem.type ? null : menuItem.path }}"
        >
          <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
          <span>{{menuItem.title | translate}}</span>
          <div class="according-menu">
            <i *ngIf="menuItem.children" class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right"></i>
          </div>
        </a>
        <!-- External Tab Link -->
        <a
          *ngIf="menuItem.type === 'extTabLink'"
          [class.link-nav]="!menuItem.children"
          [ngClass]="{active: menuItem.active}"
          class="sidebar-link sidebar-title"
          href="{{ !menuItem.type ? null : menuItem.path }}"
          target="_blank"
        >
          <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
          <span>{{menuItem.title }}</span>
          <div class="according-menu">
            <i *ngIf="menuItem.children" class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right"></i>
          </div>
        </a>
        <!-- 2nd Level Menu -->
        <ul
          *ngIf="menuItem.children"
          [ngClass]="{'menu-open': menuItem.active, 'menu-close': !menuItem.active }"
          [ngClass]="{active: menuItem.active}"
          [style.display]="menuItem.active ? 'block' : 'none'"
          class="sidebar-submenu"
        >
          <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{active: childrenItem.active}">
            <!-- Sub -->
            <a
              (click)="toggledNavActive(childrenItem)"
              *ngIf="childrenItem.type === 'sub'"
              class="submenu-title"
              href="javascript:void(0)"
            >
              <span> {{childrenItem.title | translate}}</span>
              <div class="according-menu">
                <i
                  *ngIf="childrenItem.children"
                  class="fa fa-angle-{{childrenItem.active ? 'down' : 'right'}} pull-right"
                ></i>
              </div>
            </a>
            <!-- Link -->
            <a
              *ngIf="childrenItem.type === 'link'"
              [routerLinkActiveOptions]="{exact: true}"
              [routerLink]="!childrenItem.type ? null : [childrenItem.path]"
              class="submenu-title"
              routerLinkActive="active"
            >
              <span>{{childrenItem.title | translate}}</span>
              <i *ngIf="childrenItem.children" class="fa fa-angle-down pull-right"></i>
            </a>
            <!-- External Link -->
            <a
              *ngIf="childrenItem.type === 'extLink'"
              [routerLinkActiveOptions]="{exact: true}"
              class="submenu-title"
              href="{{ !childrenItem.type ? null : childrenItem.path }}"
              routerLinkActive="active"
            >
              <span>{{childrenItem.title | translate}}</span>
              <i *ngIf="childrenItem.children" class="fa fa-angle-down pull-right"></i>
            </a>
            <!-- External Tab Link -->
            <a
              *ngIf="childrenItem.type === 'extTabLink'"
              class="submenu-title"
              href="{{ !childrenItem.type ? null : childrenItem.path }}"
              target="_blank"
            >
              <span>{{childrenItem.title | translate}}</span>
              <i *ngIf="childrenItem.children" class="fa fa-angle-down pull-right"></i>
            </a>
            <!-- 3rd Level Menu -->
            <ul
              *ngIf="childrenItem.children"
              [ngClass]="{active: childrenItem.active}"
              [ngClass]="{'menu-open': childrenItem.active, 'menu-close': !childrenItem.active }"
              [style.display]="childrenItem.active ? 'block' : 'none'"
              class="nav-sub-childmenu submenu-content"
            >
              <li *ngFor="let childrenSubItem of childrenItem.children" [ngClass]="{active: childrenSubItem.active}">
                <!-- Link -->
                <a
                  *ngIf="childrenSubItem.type === 'link'"
                  [routerLinkActiveOptions]="{exact: true}"
                  [routerLink]="!childrenSubItem.type ? null : [childrenSubItem.path]"
                  routerLinkActive="active"
                >
                  <span> {{childrenSubItem.title | translate}}</span>
                </a>
                <!-- External Link -->
                <a
                  *ngIf="childrenSubItem.type === 'extLink'"
                  [routerLinkActiveOptions]="{exact: true}"
                  href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}"
                  routerLinkActive="active"
                >
                  <span>{{childrenSubItem.title | translate}}</span>
                </a>
                <!-- External Tab Link -->
                <a
                  *ngIf="childrenSubItem.type === 'extTabLink'"
                  href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}"
                  target="_blank"
                >
                  <span>{{childrenSubItem.title | translate}}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div (click)="scrollToRight()" [class.d-none]="rightArrowNone" class="right-arrow" id="right-arrow">
    <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
  </div>
</nav>

import {
  trigger,
  animate,
  transition,
  style,
  query,
  group,
  animateChild,
  state,
  AnimationTriggerMetadata,
  AnimationQueryMetadata,
} from '@angular/animations';

export const fadeInAnimation: AnimationTriggerMetadata = trigger('fadeInAnimation', [
  transition('* => *', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ],
      { optional: true },
    ),
    query(':enter', [style({ opacity: '0' })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate('.2s ease-in-out', style({ opacity: '0' }))], { optional: true }),
      query(':enter', [animate('.2s ease-in-out', style({ opacity: '1' }))], {
        delay: 300,
        optional: true,
      }),
    ]),
    query(':enter', animateChild(), { optional: true }),
  ]),
]);

export const fadeIn: AnimationQueryMetadata[] = [
  query(':leave', style({ position: 'absolute', left: 0, right: 0, opacity: 1 }), { optional: true }),
  query(':enter', style({ position: 'absolute', left: 0, right: 0, opacity: 0 }), { optional: true }),
  query(':leave', animate('1s', style({ opacity: 0 })), { optional: true }),
  query(':enter', animate('1s', style({ opacity: 1 })), { optional: true }),
];

export const routerAnimations: AnimationTriggerMetadata = trigger('routerAnimations', [transition('* => *', fadeIn)]);

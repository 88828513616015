<div class="media profile-media">
  <div *ngIf="logo" class="logo">
    <img [src]="logo" alt="Logo" class="b-r-10 img-40" />
  </div>
  <div *ngIf="!logo" class="b-r-10 no-logo-div"><i class="fa fa-user no-logo"></i></div>
  <div class="media-body">
    <span *ngIf="merchant !== null">{{ merchant.name || "" }}</span>
    <span *ngIf="integrator !== null">{{ integrator.name || "" }}</span>
    <span *ngIf="userRole === 'Admin'">{{ user.name || "" }}</span>
    <p class="mb-0 font-roboto">{{ userRole }}<i class="middle fa fa-angle-down p-l-5"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li *ngIf="navSettings">
    <a [routerLink]="profileRoute">
      <app-feather-icons [icon]="'user'"></app-feather-icons>
      <span>{{ 'PROFILE_MENU.PROFILE' | translate }}</span></a
    >
  </li>
  <!-- <li *ngIf="navSettings">
      <a [routerLink]="'/settings'"
        ><app-feather-icons [icon]="'settings'"></app-feather-icons
        ><span>{{ 'PROFILE_MENU.SETTINGS' | translate }}</span></a
      >
    </li> -->
  <li>
    <a (click)="logOut()" [routerLink]="">
      <app-feather-icons [icon]="'log-out'"></app-feather-icons>
      <span>{{ 'PROFILE_MENU.LOGOUT' | translate }}</span></a
    >
  </li>
</ul>

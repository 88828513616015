<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-6 row title">
        <div (click)="goBack()" class="btn-back">
          <i *ngIf="show_back_button" class="fa fa-angle-left mr-2"></i>
        </div>
        <ol *ngIf="show_breadcrumb && clickable_items" class="breadcrumb">
          <li *ngFor="let item of clickable_items" class="breadcrumb-item">{{item?.label}}</li>
          <li class="breadcrumb-item active">{{active_item}}</li>
        </ol>
        <h3>{{title}}</h3>
      </div>
      <div *ngIf="show_breadcrumb && items" class="col-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard/default'">
              <app-feather-icons [icon]="'home'"></app-feather-icons>
            </a>
          </li>
          <li *ngFor="let item of items" class="breadcrumb-item">{{item}}</li>
          <li class="breadcrumb-item active">{{active_item}}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

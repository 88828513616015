import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { MerchantDetails } from 'src/app/shared/model/merchant-details.model';
import { Role } from 'src/app/shared/model/user-role.enum';
import { User } from 'src/app/shared/model/user.model';
import { Integrator } from 'src/app/shared/model/integrator.model';
import { loadWebConfig } from 'src/app/shared/utils/utils';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environment/environment';
import { IWebConfig } from 'src/app/IWebConfig';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
  navSettings = environment.navSettings;
  merchant: MerchantDetails = null;
  integrator: Integrator = null;
  logo: SafeUrl;
  userRole: String = null;
  profileRoute: String = null;
  user: User = null;
  private webconfig: IWebConfig = loadWebConfig();

  constructor(
    public authService: AuthService,
    public api: ApiService,
    public router: Router,
    public domSanitizer: DomSanitizer,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.authService.user.subscribe((user: User) => {
      this.user = user;

      if (this.user) {
        switch (this.user.role) {
          case Role.Admin:
            this.userRole = this.translate.instant('PROFILE_MENU.ADMIN');
            this.profileRoute = '/profile/admin';
            break;
          case Role.Merchant:
            this.userRole = this.translate.instant('PROFILE_MENU.MERCHANT');
            this.profileRoute = '/profile/merchant';
            this.api.getCurrentMerchant().then((merchantDetails) => {
              this.merchant = merchantDetails;
              if (this.merchant.logoData != null) {
                this.logo = this.domSanitizer.bypassSecurityTrustUrl(
                  'data:image/jpeg;base64, ' + this.merchant.logoData,
                );
              }
            });
            break;
          case Role.Support:
            this.userRole = this.translate.instant('PROFILE_MENU.SUPPORT');
            this.profileRoute = '/profile/support';
            this.api.getCurrentMerchant().then((merchantDetails) => {
              this.merchant = merchantDetails;
              if (this.merchant.logoData != null) {
                this.logo = this.domSanitizer.bypassSecurityTrustUrl(
                  'data:image/jpeg;base64, ' + this.merchant.logoData,
                );
              }
            });
            break;
          case Role.Integrator:
            this.userRole = this.translate.instant('PROFILE_MENU.INTEGRATOR');
            this.profileRoute = '/profile/integrator';
            this.api.getCurrentIntegrator().then((integratorDetails) => {
              this.integrator = integratorDetails;
              if (this.integrator.logoData != null) {
                this.logo = this.domSanitizer.bypassSecurityTrustUrl(
                  'data:image/jpeg;base64, ' + this.integrator.logoData,
                );
              }
            });
            break;
          case Role.User:
            this.userRole = this.translate.instant('PROFILE_MENU.USER');
            break;
        }
      }
    });
  }

  logOut() {
    this.authService.signOut();
    this.router.navigate(['/']);
  }
}

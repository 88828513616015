import { ChangeDetectionStrategy, Component, Input, InputSignal, OnInit, input } from '@angular/core';
import * as feather from 'feather-icons';

@Component({
  selector: 'app-feather-icons',
  standalone: true,
  template: '<i [attr.data-feather]="icon()"></i>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatherIconsComponent implements OnInit {
  public icon: InputSignal<string> = input.required<string>();

  constructor() {}

  ngOnInit() {
    setTimeout(feather.replace);
  }
}

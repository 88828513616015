import { Directive, ElementRef, AfterContentChecked, Renderer2 } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';

@Directive({
  selector: '[appAttachCopyButtons]',
})
export class AttachCopyButtonsDirective implements AfterContentChecked {
  constructor(
    private elementRef: ElementRef,
    private toaster: ToastrService,
    private logger: NGXLogger,
    private renderer: Renderer2,
  ) {}

  async ngAfterContentChecked() {
    this.addCopyButtons();
  }

  /**
   * Attach copy buttons to code snippets in markdown documentation
   */
  private addCopyButtons() {
    const codeBlocks = this.elementRef.nativeElement.querySelectorAll('pre');
    codeBlocks.forEach(
      (pre: {
        querySelector: (arg0: string) => any;
        textContent: string;
        appendChild: (arg0: HTMLButtonElement) => void;
      }) => {
        if (!pre.querySelector('button')) {
          const copy = document.createElement('button');
          const icon = document.createElement('i');
          icon.classList.add('fa', 'fa-clone');

          copy.appendChild(icon);
          copy.addEventListener('click', async () => {
            try {
              await navigator.clipboard.writeText(pre.textContent);
              this.toaster.success(`Copied to clipboard`);
            } catch (err) {
              this.toaster.error(`Unable to copy content to clipboard`);
              this.logger.error('Unable to copy content to clipboard: ', err);
            }

            copy.classList.add('copied');
            setTimeout(() => {
              copy.classList.remove('copied');
            }, 2000);
          });
          pre.appendChild(copy);
        }
      },
    );
  }
}

import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() title: string;
  @Input() items: string[];
  @Input() clickable_items: { label: string; route: string }[];
  @Input() active_item: string;
  @Input() show_breadcrumb: boolean;
  @Input() show_back_button: boolean;

  constructor(private _location: Location) {}

  public goBack() {
    this._location.back();
  }
}

export enum AccountType {
  User = 'user',
  Merchant = 'merchant',
  Integrator = 'integrator',
}

export enum LoginType {
  Pin = 'pin',
  Password = 'password',
}

export enum LoginMethod {
  Email = 'email',
  Mobile = 'mobile',
}

export enum OTPChannelType {
  SMS = 'sms',
  Email = 'email',
}

<!-- page-wrapper Start-->
<div [class]="layout.config.settings.layout_version" [ngClass]="layout.config.settings.layout_type">
  <div [ngClass]="layoutClass" class="page-wrapper" id="canvas-bookmark">
    <app-header></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper">
      <div
        [attr.sidebar-layout]="layout.config.settings.sidebar_type"
        [class.close_icon]="navServices.collapseSidebar"
        class="sidebar-wrapper"
      >
        <app-sidebar></app-sidebar>
      </div>
      <div class="page-body">
        <main [@fadeInAnimation]="getRouterOutletState(o)">
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
      <!-- footer start-->
      <!-- <footer class="footer">
              <app-footer></app-footer>
            </footer> -->
      <!-- footer End-->
    </div>
    <!-- Page Body End-->
  </div>
</div>
<div class="bg-overlay1"></div>
<!-- page-wrapper End-->

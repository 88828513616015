import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg';

import { PASSWORD_PATTERN, getCarouselImages, loadWebConfig } from 'src/app/shared/utils/utils';
import { LoginType, OTPChannelType } from 'src/app/shared/model/auth-channels';
import { ApiService } from 'src/app/shared/services/api.service';
import { IWebConfig } from 'src/app/IWebConfig';
import { WizardComponent } from '@nubebytes/angular-archwizard';

const TIMEOUT_IN_SECONDS = 60;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  @ViewChild(WizardComponent)
  public wizard: WizardComponent;

  private webconfig: IWebConfig = loadWebConfig();
  public orgId: string = this.webconfig.orgId;
  public loginType: string = this.webconfig.loginType;
  public loginMethod = this.webconfig.loginMethod;
  private apkPackageName: string = this.webconfig.apkPackageName;

  public active = 1;

  public timeLeft: number = TIMEOUT_IN_SECONDS;
  public interval = 0;

  public isShortPassword = this.loginType === LoginType.Pin;
  public revealPassword = false;

  public passwordValueForm: FormGroup = new FormGroup({});
  public passcodeValue = {
    passcode: '',
    confirmPasscode: '',
    valid: false,
  };

  public credentialsForm: FormGroup;
  public credentialsFormValid = false;
  public otpForm: FormGroup;
  public passwordForm: FormGroup;

  public backgroundImage = getCarouselImages();

  public verifyGUID: string = null;
  public verifiedOTP = false;
  public verifyOTPFailed = false;
  public passwordFormValid = false;

  public separateDialCode = false;
  public SearchCountryField = SearchCountryField;
  public CountryISO = CountryISO;
  public PhoneNumberFormat = PhoneNumberFormat;
  public preferredCountries: CountryISO[] = [CountryISO.SouthAfrica, CountryISO.UnitedKingdom, CountryISO.UnitedStates];

  constructor(
    private api: ApiService,
    private logger: NGXLogger,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    public router: Router,
  ) {
    this.credentialsForm = this.formBuilder.group({
      mobile: [''],
      email: ['', [Validators.email]],
    });

    this.credentialsForm.valueChanges.subscribe((values) => {
      const isEmailValid = this.credentialsForm.get('email').valid;
      const isMobileValid = this.credentialsForm.get('mobile').valid;

      this.credentialsFormValid =
        isEmailValid && isMobileValid && !!((values.email && !values.mobile) || (!values.email && values.mobile));
    });

    this.passwordValueForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(PASSWORD_PATTERN)]],
      confirmPassword: ['', Validators.required, Validators.minLength(8), Validators.pattern(PASSWORD_PATTERN)],
    });

    this.otpForm = this.formBuilder.group({
      otpNumber: [''],
    });
  }

  private getUsername(): string | undefined {
    if (!this.credentialsFormValid) {
      this.logger.error('Please fill in all the required fields');
    }

    const email = this.credentialsForm.controls.email.value?.toLowerCase().trim();
    const mobile = this.credentialsForm.controls.mobile?.value?.e164Number;

    return email || mobile;
  }

  private getChannelType(): string | undefined {
    if (!this.credentialsFormValid) {
      this.logger.error('Please fill in all the required fields');
    }

    const email = this.credentialsForm.controls.email.value?.toLowerCase().trim();
    const mobile = this.credentialsForm.controls.mobile?.value?.e164Number;

    return email ? OTPChannelType.Email : mobile ? OTPChannelType.SMS : undefined;
  }

  private startTimer() {
    this.timeLeft = TIMEOUT_IN_SECONDS;
    this.interval = window.setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.handleTimeout();
      }
    }, 1000);
  }

  private stopTimer() {
    clearInterval(this.interval);
  }

  private handleTimeout() {
    this.stopTimer();
    this.timeLeft = TIMEOUT_IN_SECONDS;
    this.verifyGUID = null;
    this.resetOtpForm();
  }

  private resetOtpForm() {
    const otpNumberControl = this.otpForm.controls.otpNumber;
    otpNumberControl.clearValidators();
    otpNumberControl.updateValueAndValidity();
    otpNumberControl.setValue('');
  }

  public async getOtp(goToNextStep: boolean) {
    const username = this.getUsername();

    try {
      const usernameAvailable = await this.api.isUsernameAvailable(this.getUsername());
      if (usernameAvailable.available) {
        this.toaster.error(`Account ${username} does not exist`);
        return;
      }

      const response = await this.api.generateOtp({
        channel: this.getChannelType(),
        address: username,
        apkPackageName: this.apkPackageName,
      });

      this.toaster.success('Successfully sent you an OTP');
      this.verifyGUID = response.id;

      this.stopTimer();
      this.startTimer();

      if (goToNextStep) {
        this.wizard.goToNextStep();
      }
    } catch (e) {
      this.toaster.error('Failed to send OTP:', e.message);
      this.logger.error('Failed to send OTP:', e);
    }
  }

  public async verifyOtp(code: string) {
    try {
      await this.api.verifyOtp({
        id: this.verifyGUID,
        code: code,
        channel: this.getChannelType(),
        address: this.getUsername(),
      });

      this.toaster.success(`Successfully verified your OTP`);
      this.wizard.goToNextStep();
    } catch (e) {
      this.toaster.error('Failed to verify OTP');
      this.logger.error('Failed to verify OTP:', e);
    }
  }

  public async resetPassword() {
    try {
      await this.api.forgotPassword({
        id: this.verifyGUID,
        address: this.getUsername(),
        channel: this.getChannelType(),
        password:
          this.loginType === LoginType.Pin
            ? this.passcodeValue.passcode
            : this.passwordValueForm.controls.password.value,
      });
      this.toaster.success(`Successfully updated your ${this.loginType}.`);
      this.router.navigate(['auth/login']);
    } catch (e) {
      this.logger.error('Failed to reset password');
      this.toaster.error('Failed to reset password:', e);
    }
  }

  public enterPasscode(password: string) {
    this.passcodeValue.passcode = password;
    this.passcodeValue.valid = this.passcodeValue.passcode === this.passcodeValue.confirmPasscode;
  }

  public enterConfirmPasscode(password: string) {
    this.passcodeValue.confirmPasscode = password;
    this.passcodeValue.valid = this.passcodeValue.passcode === this.passcodeValue.confirmPasscode;
  }

  public showPassword() {
    this.revealPassword = !this.revealPassword;
  }

  public passwordsMatch(): boolean {
    const password = this.passwordValueForm.get('password').value;
    const confirmPassword = this.passwordValueForm.get('confirmPassword').value;
    return password === confirmPassword;
  }
}

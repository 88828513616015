import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-simple-modal',
  templateUrl: './simple-modal.component.html',
  styleUrls: ['./simple-modal.component.scss'],
})
export class SimpleModalComponent implements OnInit {
  @Input() fields: { label: string; type: string; id: string; required: boolean; columnAlign: boolean }[];
  @Input() title: string;
  @Input() subtitle: string;
  @Input() primaryBtnLabel: string;
  @Input() secondaryBtnLabel: string;
  @Input() tertiaryBtnLabel: string;

  @Output() primaryButtonClicked: EventEmitter<any> = new EventEmitter();
  @Output() secondaryButtonClicked: EventEmitter<any> = new EventEmitter();
  @Output() tertiaryButtonClicked: EventEmitter<any> = new EventEmitter();

  public formGroup: FormGroup = new FormGroup({});
  public validators: any;
  public additionalValidator: any;
  public validate = false;

  constructor(
    public activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({});
    if (this.fields) {
      this.fields.forEach((field) => {
        this.formGroup.addControl(field.id, this.formBuilder.control('', Validators.required));
      });

      if (this.validators && this.additionalValidator)
        this.overwriteFormValidator(this.validators, this.additionalValidator);
    }
  }

  overwriteFormValidator(validators: any, additionalValidator: any) {
    this.formGroup = this.formBuilder.group(validators, additionalValidator);
  }

  getFormValues(type: string) {
    const formValues: { name: string; value: any }[] = [];
    const form = this.formGroup.controls;

    let formValid = true;
    for (const key in form) {
      if (form[key].status === 'INVALID') {
        formValid = false;
        break;
      }

      const field = this.fields.find((f) => f.id === key);
      if (field && !field.required) {
        formValid = true;
      }
    }

    if (!formValid) {
      this.toaster.error('Please fill in the form and try again');
      return null;
    }

    if (!this.formGroup) {
      this.toaster.error('Form is not valid');
      return null;
    }

    Object.keys(this.formGroup.controls).forEach((field) => {
      if (this.formGroup.get(field)) formValues.push({ name: field, value: this.formGroup.get(field).value });
    });

    return { type, fields: formValues };
  }

  onClickPrimary() {
    const formValues: any = this.getFormValues('submit');
    if (formValues) {
      this.primaryButtonClicked.emit({ clicked: true, formValues });
    }
  }

  onClickSecondary() {
    this.secondaryButtonClicked.emit({ clicked: true });
  }

  onClickTertiary() {
    this.tertiaryButtonClicked.emit({ clicked: true });
  }
}

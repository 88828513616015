<div class="container-fluid">
  <div class="row">
    <div
      class="col-xl-5 pl-0 pr-0 authBackground"
      [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }"
    ></div>

    <div class="col-xl-7 p-0">
      <div class="login-card">
        <div>
          <div *ngIf="loginType === 'pin'; else otherLoginFlavours" class="login-main">
            <form class="theme-form">
              <h4>Create New Password</h4>
              <p>Your previous password must be different from previously used passwords.</p>

              <div class="form-group">
                <label class="col-form-label">Enter Password</label>
                <div class="password">
                  <code-input
                    class="code-input"
                    (codeChanged)="onPasswordChanged($event)"
                    (codeCompleted)="onPasswordCompleted($event)"
                    [codeLength]="4"
                    [isCodeHidden]="true"
                    id="password"
                  >
                  </code-input>
                </div>
              </div>

              <div class="form-group">
                <label class="col-form-label">Confirm Password</label>
                <div class="password">
                  <code-input
                    class="code-input"
                    (codeChanged)="onConfirmPasswordChanged($event)"
                    (codeCompleted)="onConfirmPasswordCompleted($event)"
                    [codeLength]="4"
                    [isCodeHidden]="true"
                    id="confirmPassword"
                  >
                  </code-input>
                </div>
              </div>

              <div class="form-group mt-4 mb-0">
                <button
                  (click)="resetPassword()"
                  [disabled]="!passwordFormValid"
                  class="btn btn-primary btn-block"
                  type="button"
                >
                  <span>Reset Password</span>
                </button>
              </div>
            </form>
          </div>

          <ng-template #otherLoginFlavours>
            <div class="login-main">
              <form *ngIf="!passwordRequested" [formGroup]="passwordForm" class="theme-form">
                <h4>Create New Password</h4>
                <p>Your previous password must be different from previously used passwords.</p>

                <div class="form-group">
                  <label class="col-form-label">Password</label>
                  <input
                    [type]="revealPassword ? 'text' : 'password'"
                    class="form-control"
                    formControlName="password"
                    name="login[password]"
                    placeholder="Enter your new password"
                  />
                  <div (click)="showPassword()" *ngIf="!revealPassword" class="show-hide">
                    <span class="revealPassword"></span>
                  </div>
                  <div (click)="showPassword()" *ngIf="revealPassword" class="show-hide">
                    <span class="Hide"></span>
                  </div>
                  <ng-container
                    *ngIf="passwordForm.get('password').hasError('required') && passwordForm.get('password').touched"
                    class="invalid"
                  >
                    <div class="invalid">Password is required</div>
                  </ng-container>
                  <ng-container
                    *ngIf="passwordForm.get('password').hasError('minlength') && passwordForm.get('password').touched"
                    class="invalid"
                  >
                    <div class="invalid">Password must be 8 characters or more</div>
                  </ng-container>
                </div>

                <div class="form-group">
                  <label class="col-form-label">Confirm New Password</label>
                  <input
                    [type]="revealPassword ? 'text' : 'password'"
                    class="form-control"
                    formControlName="confirmPassword"
                    name="login[password]"
                    placeholder="Enter your new confirm password"
                  />
                  <ng-container
                    *ngIf="passwordForm.get('confirmPassword').hasError('required') && passwordForm.get('confirmPassword').touched"
                    class="invalid"
                  >
                    <div class="invalid">Password confirmation is required</div>
                  </ng-container>
                  <ng-container
                    *ngIf="passwordForm.get('confirmPassword').hasError('minlength') && passwordForm.get('confirmPassword').touched"
                    class="invalid"
                  >
                    <div class="invalid">Password confirmation must be 8 characters or more</div>
                  </ng-container>
                  <ng-container
                    *ngIf="passwordForm.get('confirmPassword').hasError('mustMatch') && passwordForm.get('confirmPassword').touched"
                    class="invalid"
                  >
                    <div class="invalid">Passwords don't match</div>
                  </ng-container>
                </div>
                <div class="form-group mt-4 mb-0">
                  <button
                    (click)="resetPassword()"
                    [disabled]="!passwordForm.valid"
                    class="btn btn-primary btn-block"
                    type="button"
                  >
                    <span>Reset Password</span>
                  </button>
                </div>
                <hr />
                <p class="mt-4 mb-0">
                  Already have an account?
                  <a [routerLink]="'/'" class="ml-2">Sign In</a>
                </p>
              </form>
              <form *ngIf="passwordRequested" class="theme-form">
                <h4>Password Reset Successfully!</h4>
                <p>Your password was reset successfully, you can login with your new password.</p>
                <a [routerLink]="'/'" class="btn btn-primary btn-block">Sign in to my account</a>
              </form>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Components
import { BreadcrumbComponent } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import { FeatherIconsComponent } from 'src/app/shared/components/feather-icons/feather-icons.component';
import { FooterComponent } from 'src/app/shared/components/footer/footer.component';
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { ContentComponent } from 'src/app/shared/components/layout/content/content.component';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { CardLoaderComponent } from 'src/app/shared/components/card-loader/card-loader.component';
import { SidebarComponent } from 'src/app/shared/components/sidebar/sidebar.component';
import { TapToTopComponent } from 'src/app/shared/components/tap-to-top/tap-to-top.component';
import { SimpleModalComponent } from 'src/app/shared/components/modals/simple-modal/simple-modal.component';

// Header Elements Components
import { SearchComponent } from 'src/app/shared/components/header/elements/search/search.component';
import { LanguagesComponent } from 'src/app/shared/components/header/elements/languages/languages.component';
import { NotificationComponent } from 'src/app/shared/components/header/elements/notification/notification.component';
import { BookmarkComponent } from 'src/app/shared/components/header/elements/bookmark/bookmark.component';
import { CartComponent } from 'src/app/shared/components/header/elements/cart/cart.component';
import { MessageBoxComponent } from 'src/app/shared/components/header/elements/message-box/message-box.component';
import { MyAccountComponent } from 'src/app/shared/components/header/elements/my-account/my-account.component';

// Services
import { LayoutService } from 'src/app/shared/services/layout.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ApiService } from 'src/app/shared/services/api.service';

// Date Pipes
import { DateFormatterPipe, DateTimeFormatterPipe } from 'src/app/shared/pipe/convert-date.pipe';
import { CurrencySymbolPipe } from 'src/app/shared/pipe/currency-symbol.pipe';
import { EncryptionInterceptor } from 'src/app/shared/services/encrypt.service';
import { JwtExpiryInterceptor } from 'src/app/shared/services/jwt.service';
import { ShortNumberSuffixPipe } from 'src/app/shared/pipe/short-number-suffix.pipe';

// Directives
import { AttachCopyButtonsDirective } from 'src/app/shared/directives/attach-copy-buttons.directive';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    LoaderComponent,
    CardLoaderComponent,
    TapToTopComponent,
    SearchComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    DateFormatterPipe,
    DateTimeFormatterPipe,
    CurrencySymbolPipe,
    SimpleModalComponent,
    ShortNumberSuffixPipe,
    AttachCopyButtonsDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    NgxDatatableModule,
    FeatherIconsComponent,
  ],
  providers: [
    NavService,
    LayoutService,
    AuthService,
    ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtExpiryInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncryptionInterceptor,
      multi: true,
    },
  ],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LoaderComponent,
    CardLoaderComponent,
    BreadcrumbComponent,
    TapToTopComponent,
    DateFormatterPipe,
    DateTimeFormatterPipe,
    ShortNumberSuffixPipe,
    AttachCopyButtonsDirective,
    FeatherIconsComponent,
  ],
})
export class SharedModule {}

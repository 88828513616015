<form [class.open]="navServices.search" class="form-inline search-full">
  <div class="form-group w-100">
    <input
      (keyup)="searchTerm(text)"
      [(ngModel)]="text"
      [ngModelOptions]="{standalone: true}"
      autocomplete="off"
      class="form-control-plaintext"
      placeholder="Search..."
      type="text"
    />
    <app-feather-icons (click)="searchToggle()" [icon]="'x'" class="close-search"></app-feather-icons>
    <div *ngIf="menuItems?.length" [class.is-open]="searchResult" class="Typeahead-menu" id="search-outer">
      <div *ngFor="let menuItem of menuItems | slice:0:8" class="ProfileCard u-cf">
        <div class="ProfileCard-avatar">
          <app-feather-icons [icon]="menuItem.icon" class="header-search"></app-feather-icons>
        </div>
        <div class="ProfileCard-details">
          <div class="ProfileCard-realName">
            <a (click)="removeFix()" [routerLink]="menuItem?.path" class="realname">{{menuItem?.title}}</a>
          </div>
        </div>
      </div>
    </div>
    <div [class.is-open]="searchResultEmpty" class="Typeahead-menu">
      <div class="tt-dataset tt-dataset-0">
        <div class="EmptyMessage">No results found</div>
      </div>
    </div>
  </div>
</form>

<div class="container-fluid">
  <div class="row">
    <div
      class="col-xl-5 pl-0 pr-0 authBackground"
      [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }"
    ></div>

    <div class="col-xl-7 p-0">
      <div class="login-card">
        <div class="login-main">
          <form [formGroup]="credentialsForm" class="theme-form">
            <h4>Log in to your account</h4>
            <p>Enter your mobile number and password to sign in</p>

            <!-- BOTH LOGIN METHODS -->
            <div *ngIf="loginMethod.includes('mobile') && loginMethod.includes('email'); else singleLogin">
              <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1" (click)="clearInput()">
                  <a ngbNavLink><strong>Email</strong></a>
                  <ng-template ngbNavContent>
                    <div class="form-group">
                      <input
                        class="form-control"
                        formControlName="username"
                        placeholder="name@email.com"
                        type="email"
                      />
                      <ng-container
                        *ngIf="credentialsForm.get('username').hasError('required') && credentialsForm.get('username').touched"
                        class="invalid"
                      >
                        <div class="invalid">Email is required</div>
                      </ng-container>
                      <ng-container
                        *ngIf="credentialsForm.get('username').hasError('email') && credentialsForm.get('username').touched"
                        class="invalid"
                      >
                        <div class="invalid">Email not valid</div>
                      </ng-container>
                    </div>
                  </ng-template>
                </li>

                <li [ngbNavItem]="2" (click)="clearInput()">
                  <a ngbNavLink><strong>Mobile</strong></a>
                  <ng-template ngbNavContent>
                    <div class="form-group">
                      <div class="input-group">
                        <ngx-intl-tel-input
                          [cssClass]="'custom'"
                          [enableAutoCountrySelect]="true"
                          [enablePlaceholder]="true"
                          [maxLength]="15"
                          [numberFormat]="PhoneNumberFormat.National"
                          [phoneValidation]="true"
                          [preferredCountries]="preferredCountries"
                          [searchCountryField]="[
                            SearchCountryField.Iso2,
                            SearchCountryField.Name
                          ]"
                          [searchCountryFlag]="true"
                          [selectFirstCountry]="false"
                          [selectedCountryISO]="CountryISO.SouthAfrica"
                          [separateDialCode]="separateDialCode"
                          formControlName="username"
                          name="phone"
                        >
                        </ngx-intl-tel-input>
                      </div>
                      <ng-container
                        *ngIf="credentialsForm.get('username').hasError('required') && credentialsForm.get('username').touched"
                        class="invalid"
                      >
                        <div class="invalid">Mobile number is required</div>
                      </ng-container>
                      <ng-container
                        *ngIf="credentialsForm.get('username').hasError('pattern') && credentialsForm.get('username').touched"
                        class="invalid"
                      >
                        <div class="invalid">Mobile number not valid</div>
                      </ng-container>
                    </div>
                  </ng-template>
                  <ngb-alert (closed)="loginPinFailed=false" *ngIf="loginPinFailed" type="danger">
                    Incorrect mobile number or password
                  </ngb-alert>
                </li>
              </ul>

              <form #form="ngForm">
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
              </form>
            </div>

            <!-- SINGLE LOGIN METHOD -->
            <ng-template #singleLogin>
              <div *ngIf="loginMethod.includes('email')" class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control" formControlName="username" placeholder="name@email.com" type="email" />
                <ng-container
                  *ngIf="credentialsForm.get('username').hasError('required') && credentialsForm.get('username').touched"
                  class="invalid"
                >
                  <div class="invalid">Email is required</div>
                </ng-container>
                <ng-container
                  *ngIf="credentialsForm.get('username').hasError('email') && credentialsForm.get('username').touched"
                  class="invalid"
                >
                  <div class="invalid">Email not valid</div>
                </ng-container>
              </div>

              <div *ngIf="loginMethod.includes('mobile')" class="form-group">
                <label class="col-form-label">Mobile Number</label>
                <ngx-intl-tel-input
                  [cssClass]="'custom'"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [maxLength]="15"
                  [numberFormat]="PhoneNumberFormat.National"
                  [phoneValidation]="true"
                  [preferredCountries]="preferredCountries"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]"
                  [searchCountryFlag]="true"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.SouthAfrica"
                  [separateDialCode]="separateDialCode"
                  formControlName="username"
                  name="phone"
                >
                </ngx-intl-tel-input>
                <ng-container
                  *ngIf="credentialsForm.get('username').hasError('required') && credentialsForm.get('username').touched"
                  class="invalid"
                >
                  <div class="invalid">Mobile number is required</div>
                </ng-container>
                <ng-container
                  *ngIf="credentialsForm.get('username').hasError('pattern') && credentialsForm.get('username').touched"
                  class="invalid"
                >
                  <div class="invalid">Mobile number not valid</div>
                </ng-container>
              </div>
            </ng-template>

            <!-- PIN -->
            <div class="form-group mt-3" *ngIf="loginType === 'pin'; else loginPassword">
              <label class="col-form-label f-14"><strong>Password</strong></label>
              <div class="pin">
                <code-input
                  class="code-input"
                  (codeCompleted)="login($event)"
                  [codeLength]="4"
                  [isCodeHidden]="true"
                  id="codeInput"
                >
                </code-input>
              </div>
              <ngb-alert
                class="mt-3"
                (closed)="incorrectPassword=false"
                *ngIf="incorrectPassword"
                type="danger"
                dismissible
              >
                {{ active === 1 ? 'Incorrect email or password' : 'Incorrect mobile number or password' }}
              </ngb-alert>
            </div>

            <!-- PASSWORD -->
            <ng-template #loginPassword>
              <div class="form-group">
                <label class="col-form-label f-14"><strong>Password</strong></label>
                <input
                  class="form-control"
                  formControlName="password"
                  placeholder="Enter your password"
                  required="true"
                  [type]="revealPassword ? 'text' : 'password'"
                />
                <div class="show-hide" (click)="showPassword()" *ngIf="!revealPassword">
                  <span class="show"></span>
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="revealPassword">
                  <span class="Hide"></span>
                </div>
                <div
                  *ngIf="credentialsForm.controls.password.touched && credentialsForm.controls.password.errors?.required"
                  class="text text-danger mt-1"
                >
                  Password is required
                </div>
              </div>
              <ngb-alert class="mt-3" (closed)="incorrectPassword=false" *ngIf="incorrectPassword" type="danger">
                {{ active === 1 ? 'Incorrect email or password' : 'Incorrect mobile number or password' }}
              </ngb-alert>
              <br />
              <button
                (click)="login()"
                [class.loader--text]="authService.showLoader"
                [disabled]="!credentialsForm.valid"
                class="btn btn-primary btn-block forgot-password"
                type="button"
              >
                <span>Login</span>
              </button>
            </ng-template>

            <p class="mt-4 mb-0">
              Forgot your password?
              <a [routerLink]="'/auth/forgot-password'">Reset it</a>
            </p>

            <p *ngIf="isAllowedToCreateAccount" class="mb-0">
              Don't have an account yet?
              <a [routerLink]="'/auth/register'" class="ml-1">Create an account</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { delay, map, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map((v) => v[1]),
  );

  public title = 'Halo Portals';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private authService: AuthService,
    private cookieService: CookieService,
    public translate: TranslateService,
    private router: Router,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae', 'ru']);
    }

    // Check access token set in the cookies to sign in if a valid token
    if (!this.authService.isLoggedIn) {
      this.readAccessTokenCookies();
    } else {
      const route = this.cookieService.get(AuthService.APP_ROUTE);
      if (route) {
        this.router.navigate([route]);
      }
    }
  }

  private readAccessTokenCookies() {
    const accessToken = this.cookieService.get(AuthService.ACCESS_TOKEN);

    if (accessToken) {
      this.authService
        .loginWithToken(accessToken)
        .then((success) => {
          if (success) {
            const route = this.cookieService.get(AuthService.APP_ROUTE);
            if (route) {
              this.router.navigate([route]);
            } else {
              this.router.navigate(['/router']);
            }
          } else {
            this.router.navigate(['auth/login']);
          }
        })
        .catch((e) => {
          this.router.navigate(['auth/login']);
        });
    }
  }
}
